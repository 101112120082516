import {
    createStore
} from 'vuex'

import createPersistedState from 'vuex-persistedstate'

import {
    userBasisInfo,
    findDictInfo
} from '@/api/user.js'
export default createStore({
    state: {
        isLoading: false,
        userInfo: {},
        dict: {},
        redirect: null
    },
    getters: {
        bankInfo(state) {
            if (state.userInfo) {
                return {
                    accountNo: state.userInfo.accountNo,
                    bank: state.userInfo.bank,
                }
            } else {
                return {
                    accountNo: null,
                    bank: null
                }
            }

        },
        contactMethod(state) {
            return {
                email: state.dict.feedbackEmail,
                phone: state.dict.customerServicePhone,
                whatsapp: state.dict.whatsapp,
            }
        },
        getWorkingtimeById: (state) => (id) => {
            return state.dict.workingTimePeriordTypes.find(i => i.id === id)
        },
        getPaydayTypeById: (state) => (id) => {
            return state.dict.paydayTypes.find(i => i.id === id)
        },
        getIndustryById: (state) => (id) => {
            return state.dict.industryTypes.find(i => i.id === id)
        },
        getMaritalStatusById: (state) => (id) => {
            return state.dict.maritalStatus.find(i => i.id === id)
        },
        getEducationTypesById: (state) => (id) => {
            return state.dict.educationTypes.find(i => i.id === id)
        },
        maritalStatus(state) {
            return state.dict.maritalStatus
        },
        educationTypes(state) {
            return state.dict.educationTypes
        },
        contactList(state) {
            if (state.userInfo) {
                return state.userInfo.contactPersons
            } else {
                return []
            }
        },
    },
    mutations: {
        setLoading(state, _bol) {
            state.isLoading = _bol
        },
        setUserInfo(state, value) {
            state.userInfo = value
        },
        setDict(state, value) {
            state.dict = value
        },
        setRedirect(state, value) {
            state.redirect = value
        },
    },
    actions: {
        async getUserInfo({
            commit
        }) {
            return new Promise((resolve, reject) => {
                userBasisInfo().then(data => {
                    commit('setUserInfo', data)
                    resolve(data)
                }).catch((err) => {
                    console.log(err)
                    reject()
                })
            })

        },
        async getDict({
            commit
        }) {
            return new Promise((resolve, reject) => {
                findDictInfo().then((data) => {
                    commit('setDict', data)
                    resolve(data)
                }).catch(err => {
                    console.log(err)
                    reject(err)
                })
            })
        }
    },
    modules: {},
    plugins: [createPersistedState()]
})