<template>
  <van-popup
    v-model:show="isShow"
    safe-area-inset-bottom
    position="center"
    @close="$emit('update:visible', false)"
  >
    <div class="logout">
      <div class="logout-title">
        <img
          class="logout-title-img"
          src="../../../assets/icons/icon_fail@3x.png"
          alt=""
        />
        <div class="logout-title-center">Cierre de sesión fallido</div>
        <div>No puedes eliminar en este momento</div>
      </div>
      <div class="logout-text">
        <div class="logout-text-title">Las razones son las siguientes:</div>
        Tiene un préstamo en curso y no puede cerrar la cuenta ahora, complete
        el préstamo y luego cierre la cuenta.
      </div>
      <div class="logout-option">
        <div class="logout-option-btn" @click="toRepayment">Ver préstamos</div>
      </div>
    </div>
  </van-popup>
</template>
<script>
import { ref, watch } from "vue";
import { Popup } from "vant";
import { useRouter } from "vue-router";
import { latest } from "../../../api/order";
export default {
  props: {
    visible: { type: Boolean, default: false },
  },
  emits: ["update:visible"],
  components: { [Popup.name]: Popup },
  setup(props) {
    const isShow = ref(false);
    const router = useRouter();
    watch(
      () => props.visible,
      (val) => {
        isShow.value = val;
      }
    );
    async function toRepayment() {
      const res = await latest();
      router.push({
        path: "/repaymentDetail",
        query: {
          applicationId: res.id,
        },
      });
    }
    return {
      isShow,
      toRepayment,
    };
  },
};
</script>
<style lang="less" scoped>
.primary-btn,
.default-btn {
  height: 44px;
  font-size: 16px;
}
.logout {
  width: 325px;
  &-title {
    margin: 0 auto;
    padding: 23px 0 20px;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    border-radius: 25px 25px 0 0;
    background-size: 100% 100%;
    background: linear-gradient(to right, #ee4747, #f78b8b);
    &-img {
      width: 65px;
      height: 65px;
      display: inline-block;
    }
    &-center {
      margin: 15px 0 10px;
      height: 16px;
      font-size: 21px;
      font-weight: bold;
      color: #fff;
    }
  }
  &-text {
    padding: 30px 20px;
    color: #5e5d6e;
    font-size: 15px;
    border-radius: 0 0 25px 25px;
    background-color: #fff;
    &-title {
      margin: 0 0 12px;
      color: #100c33;
      font-weight: bold;
    }
  }
  &-option {
    margin: 30px 0 0;
    &-btn {
      height: 55px;
      line-height: 55px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      border-radius: 25px;
      background: linear-gradient(270deg, #f78b8b 0%, #ee4747 100%);
    }
  }
}
</style>
