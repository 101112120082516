<template>
  <div class="identityInfo">
    <NavBack title="Información de identidad"></NavBack>
    <div class="identityInfo-card">
      <div class="identityInfo-card-row">
        <div class="identityInfo-card-row-title">
          <img
            class="identityInfo-card-row-title-icon"
            src="../../assets/icons/icon_identity@3x.png"
            alt=""
          />
          IFE/INE
        </div>
        <div class="identityInfo-card-row-imgs">
          <van-image
            class="identityInfo-card-row-imgs-item"
            :src="userInfo?.positivePhotoUrl"
            fit="contain"
          />

          <van-image
            class="identityInfo-card-row-imgs-item"
            :src="userInfo?.oppositeIdPhotoUrl"
            fit="contain"
          />
        </div>
      </div>
    </div>
    <div class="identityInfo-card">
      <div class="identityInfo-card-row">
        <div class="identityInfo-card-row-title">Nombre</div>
        <div
          class="identityInfo-card-row-value identityInfo-card-row-value-active"
        >
          {{ userInfo?.name || "--" }}
        </div>
      </div>
      <div class="identityInfo-card-row">
        <div class="identityInfo-card-row-title">Apellido paterno</div>
        <div
          class="identityInfo-card-row-value identityInfo-card-row-value-active"
        >
          {{ userInfo?.firstName || "--" }}
        </div>
      </div>
      <div class="identityInfo-card-row">
        <div class="identityInfo-card-row-title">Apellido materno</div>
        <div
          class="identityInfo-card-row-value identityInfo-card-row-value-active"
        >
          {{ userInfo?.middleName || "--" }}
        </div>
      </div>
      <div class="identityInfo-card-row">
        <div class="identityInfo-card-row-title">CURP</div>
        <div
          class="identityInfo-card-row-value identityInfo-card-row-value-active"
        >
          {{ userInfo?.certificateNo || "--" }}
        </div>
      </div>
      <div class="identityInfo-card-row">
        <div class="identityInfo-card-row-title">Género</div>
        <div
          class="identityInfo-card-row-value identityInfo-card-row-value-active"
        >
          <van-radio-group v-model="sex" direction="horizontal">
            <van-radio disabled :name="2"
              >Hombre
              <template #icon="props">
                <img
                  class="identityInfo-card-row-value-radio"
                  :src="
                    props.checked
                      ? require('../../assets/icons/icon_circle_sex@3x.png')
                      : require('../../assets/icons/icon_circle_sex2@3x.png')
                  "
                />
              </template>
            </van-radio>
            <van-radio disabled :name="1"
              >Mujer
              <template #icon="props">
                <img
                  class="identityInfo-card-row-value-radio"
                  :src="
                    props.checked
                      ? require('../../assets/icons/icon_circle_sex@3x.png')
                      : require('../../assets/icons/icon_circle_sex2@3x.png')
                  "
                />
              </template>
            </van-radio>
          </van-radio-group>
        </div>
      </div>
      <div class="identityInfo-card-row">
        <div class="identityInfo-card-row-title">Fecha de cumpleaños</div>
        <div
          class="identityInfo-card-row-value identityInfo-card-row-value-active"
        >
          {{
            userInfo?.birthday
              ? $dayjs(userInfo.birthday).format("DD-MM-YYYY")
              : "--"
          }}
        </div>
      </div>
    </div>
    <div class="identityInfo-card">
      <div class="identityInfo-card-row">
        <div class="identityInfo-card-row-title">Dirección residencial</div>
        <div
          class="identityInfo-card-row-value"
          :class="{ 'identityInfo-card-row-value-active': !isReview }"
        >
          <div class="identityInfo-card-row-value-input">
            {{
              userInfo?.resideProvince
                ? `${userInfo.resideProvince}-${userInfo.resideCity}-${userInfo.resideBarangay || ''}`
                : "Por favor elige"
            }}
          </div>

          <img
            class="identityInfo-card-row-value-arrow"
            src="../../assets/icons/arrow_step1@3x.png"
          />
        </div>
        <!-- <textarea
          class="identityInfo-card-row-textarea"
          :class="{ 'identityInfo-card-row-value-active': !isReview }"
          placeholder="Direccion"
          :disabled="isReview"
          v-model="userInfo.resideAddress"
        ></textarea> -->
        <div
          class="identityInfo-card-row-textarea"
          :class="{ 'identityInfo-card-row-value-active': !isReview }"
        >
          {{ userInfo?.resideAddress || "--" }}
        </div>
      </div>
      <div class="identityInfo-card-row">
        <div class="identityInfo-card-row-title">Estado civil</div>
        <div
          class="identityInfo-card-row-value"
          :class="{ 'identityInfo-card-row-value-active': !isReview }"
        >
          <div class="identityInfo-card-row-value-input">
            {{ maritalStatus?.name || "--" }}
          </div>
          <img
            class="identityInfo-card-row-value-arrow"
            src="../../assets/icons/arrow_step1@3x.png"
          />
        </div>
      </div>
      <div class="identityInfo-card-row">
        <div class="identityInfo-card-row-title">Numero de niños</div>
        <div
          class="identityInfo-card-row-value"
          :class="{ 'identityInfo-card-row-value-active': !isReview }"
        >
          <div class="identityInfo-card-row-value-input">
            {{ userInfo?.childrenNumber || '0'}}
          </div>

          <img
            class="identityInfo-card-row-value-arrow"
            src="../../assets/icons/arrow_step1@3x.png"
          />
        </div>
      </div>
      <div class="identityInfo-card-row">
        <div class="identityInfo-card-row-title">Grado más alto (opcional)</div>
        <div
          class="identityInfo-card-row-value"
          :class="{ 'identityInfo-card-row-value-active': !isReview }"
        >
          <div class="identityInfo-card-row-value-input">
            {{ educationTypes?.name || "--" }}
          </div>
          <img
            class="identityInfo-card-row-value-arrow"
            src="../../assets/icons/arrow_step1@3x.png"
          />
        </div>
      </div>
    </div>
    <!-- <div class="identityInfo-bottom">
      <van-button
        class="primary-btn"
        :disabled="isReview"
        round
        block
        @click="submit"
        >Próximo paso</van-button
      >
    </div> -->
  </div>
  <RetainPopup v-model:visible="visibleRetainPopup" :type="0"></RetainPopup>
</template>

<script>
import { RadioGroup, Radio } from "vant";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import RetainPopup from "./components/retainPopup.vue";
export default {
  name: "identityInfo",
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    RetainPopup,
  },
  setup() {
    const store = useStore();
    const submit = function () {};
    const isReview = ref(true);
    const visibleRetainPopup = ref(false);
    const checked = ref("1");
    const userInfo = computed(() => store.state.userInfo);
    console.log(userInfo.value)
    const sex = computed(() => (userInfo.value?.sex ? userInfo.value.sex : 1));

    const maritalStatus = userInfo.value?.maritalStatus
      ? store.getters.getMaritalStatusById(userInfo.value.maritalStatus)
      : false;
    const educationTypes = userInfo.value?.education
      ? store.getters.getEducationTypesById(userInfo.value.education)
      : "--";
    return {
      submit,
      isReview,
      visibleRetainPopup,
      checked,
      userInfo,
      maritalStatus,
      educationTypes,
      sex,
    };
  },
};
</script>

<style lang="less" scoped>
.identityInfo {
  position: relative;
  padding: 0 0 100px 0;
  height: 100vh;
  overflow-y: scroll;
  background-color: #f2f4f7;
  &-card {
    margin: 14.5px 10px;
    padding: 20px 15px 6px;
    font-weight: bold;
    border-radius: 25px;
    background-color: #fff;
    &-row {
      margin: 0 0 20px;
      &-title {
        margin: 0 0 6px;
        font-size: 16px;
        color: #3f3d5a;
        &-icon {
          margin: 0 10px 0 0;
          width: 24px;
          height: 24px;
          vertical-align: -6px;
        }
      }
      &-imgs {
        &-item {
          margin: 14px 0 0 0;
          width: 100%;
          height: 200px;
          border-radius: 25px;
        }
      }
      &-textarea {
        margin: 10px 0 0;
        padding: 20px 16px;
        width: 100%;
        height: 100px;
        color: #888f99;
        font-size: 16px;
        border-radius: 8px;
        border: none;
        resize: none;
        background-color: #f2f4f7;
      }
      &-value {
        padding: 10px 16px;
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #888f99;
        border-radius: 8px;
        background-color: #f2f4f7;
        &-active {
          color: #130e3d;
        }
        &-input {
          background-color: transparent;
          border: none;
        }

        &-arrow {
          margin: 0 0 0 auto;
          width: 11px;
          height: 11px;
        }
        &-radio {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  &-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 7.5px 15px;
    background-color: #fff;
  }
}
</style>