<template>
  <div class="repayment-panel">
    <div class="repayment-panel-title">Selección de método de pago</div>
    <div class="repayment-panel-empty" v-if="payList.length == 0">
      No hay lista de métodos de pago disponibles
    </div>
    <template v-else>
      <van-popover v-model:show="showPopover">
        <div class="repayment-panel-list">
          <div class="repayment-panel-list-item" v-for="(i, index) of payList" :key="index" @click="getPayInfo(i)">
            {{ i.bankName }}
          </div>
        </div>
        <template #reference>
          <div class="repayment-panel-payDetail-bank">
            <img class="repayment-panel-payDetail-item-row-icon"
              :src="choosePay.id == 1 ? require('../../../assets/icons/repay_card1@3x.png') : require('../../../assets/icons/icon_money_c@3x.png')" />
            <span>{{ choosePay.bankName }}</span>
            <img class="repayment-panel-payDetail-item-row-arrow" src="../../../assets/icons/triangle_white@3x.png" />
          </div>
        </template>
      </van-popover>
      <div class="repayment-panel-payDetail">
        <img class="repayment-panel-payDetail-arrow" src="../../../assets/icons/icon_bank_top@3x.png">
        <div class="repayment-panel-payDetail-item">
          <div class="repayment-panel-payDetail-item-row">
            <img class="repayment-panel-payDetail-item-row-icon" src="../../../assets/icons/icon_money@3x.png" />
            <span>${{ $thousands(amount) }}</span>
            <!-- <span v-if="repaymentCouponId">${{ $thousands(payInfo.payMoney -
              couponRecordInfo?.repaymentCoupon?.discountAmount) }}</span>
            <span v-else>${{ $thousands(payInfo.payMoney) }}</span> -->

          </div>
          <div v-show="!payInfo.barCodeUrl" class="repayment-panel-payDetail-item-desc">Monto del pago</div>
        </div>
        <div v-if="$route.query.type != 2" class="repayment-panel-payDetail-item">
          <div class="repayment-panel-payDetail-item-row" @click="toUseCoupon()">
            <img class="repayment-panel-payDetail-item-row-icon" src="../../../assets/icons/icon_money_c@3x.png" />
            <span>Cupón</span>
            <div class="repayment-panel-payDetail-item-row-coupon">
              <span v-if="repaymentCouponId" class="repayment-panel-payDetail-item-row-coupon-amount">-${{ $thousands(
                couponRecordInfo?.repaymentCoupon?.discountAmount)
              }}</span>
              <img class="repayment-panel-payDetail-item-row-coupon-arrow"
                src="../../../assets/icons/arrow_step1@3x.png" />
            </div>

          </div>
          <div class="repayment-panel-payDetail-item-desc-coupon">
            <template v-if="!repaymentCouponId">
              <span v-if="count == 0">No hay cupones todavía</span>
              <span v-else>Tienes {{ count }} cupones para usar</span>
            </template>

            <span v-else>Cupón seleccionado</span>
          </div>
        </div>

        <template v-if="!payInfo.barCodeUrl">
          <div class="repayment-panel-payDetail-item">
            <div class="repayment-panel-payDetail-item-row">
              <img class="repayment-panel-payDetail-item-row-icon" src="../../../assets/icons/icon_bank_default@3x.png" />
              <span>{{ payInfo.vaNumber }}</span>
            </div>
            <div class="repayment-panel-payDetail-item-desc">
              Cuenta bancaria
              <div class="repayment-panel-payDetail-item-desc-copy" @click="copyNumber(payInfo.vaNumber)">
                Copiar
              </div>
            </div>
          </div>
          <div class="repayment-panel-payDetail-item">
            <div class="repayment-panel-payDetail-item-row">
              <img class="repayment-panel-payDetail-item-row-icon" src="../../../assets/icons/icon-p@3x.png" />
              <span>okpresta</span>
            </div>
            <div class="repayment-panel-payDetail-item-desc">
              Nombre del beneficiario
            </div>
          </div>
        </template>
        <template v-else>
          <div class="repayment-panel-payDetail-item">
            <van-image class="repayment-panel-payDetail-item-code" :src="payInfo.barCodeUrl" />
            <div class="repayment-panel-payDetail-item-codeText">
              {{ payInfo.barCode }}
            </div>
          </div>
        </template>

        <div class="repayment-panel-payDetail-item">
          <div class="repayment-panel-payDetail-item-row" @click="showGuide()">
            <img class="repayment-panel-payDetail-item-row-icon" src="../../../assets/icons/icon_guide@3x.png" />
            <span>Guía de pago</span>
            <img class="repayment-panel-payDetail-item-row-arrow" src="../../../assets/icons/arrow_step1@3x.png" />
          </div>
        </div>
      </div>
    </template>


  </div>
  <GuidePopup v-model:visible="guideVisible" v-model:guideType="guideType"></GuidePopup>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { copyText } from "@/utils/tools.js";
import { getCurrentInstance } from "vue";
import { Popover } from "vant";
import GuidePopup from "./GuidePopup";
import { repayList, repayVaNumberNew } from "@/api/pay.js";
import { canUseCouponNum, getCouponRecordById } from "@/api/coupon.js";
import { useRouter,useRoute } from "vue-router";

export default {
  name: "RepaymentMethod",
  components: { [Popover.name]: Popover, GuidePopup },
  props: {
    loanInfo: { type: Object, default: () => ({}), },
    amount: { type: String, default: '0' }
  },
  setup(props) {
    const route = useRoute()
    const { appContext } = getCurrentInstance();
    const $toast = appContext.config.globalProperties.$toast;
    const router = useRouter();
    const guideVisible = ref(false);
    const showPopover = ref(false);
    const guideType = ref();
    const payList = ref([]);
    const payInfo = ref({});
    const choosePay = ref({});

    const repaymentCouponId = sessionStorage.getItem("repaymentCouponId");
    const couponRecordInfo = ref();
    const count = ref(0);


    if (repaymentCouponId) {
      getCouponRecordById(repaymentCouponId).then((res) => {
        couponRecordInfo.value = res;
      });
    }
    onMounted(async () => {
      await getNum();
      getPayList();

    });
    async function getNum() {
      count.value = await canUseCouponNum({amount:props.amount});
    }
    function showGuide() {
      guideVisible.value = true;
      guideType.value = payInfo.value.repayMethod;
    }
    function copyNumber(text) {
      copyText(text);
      $toast("Copiar con éxito");
    }
    watch(() => props.loanInfo, () => {
      getPayList();
    })
    async function getPayList() {
      payList.value = await repayList();
      payInfo.value = payList.value[0];
      getPayInfo(payList.value[0]);
    }
    async function getPayInfo(i) {
      showPopover.value = false;
      choosePay.value = i;
      let params = {
        repayMethod: i.bankCode,
        orderId: props.loanInfo.orderInfo.id,
        paymoney: props.amount,
        type: route.query.type
      };
      if (repaymentCouponId) {
        params.repaymentCouponReceiveRecordId = repaymentCouponId;
      }
      payInfo.value = await repayVaNumberNew(params);
    }

    async function toUseCoupon() {
      if (count.value > 0) {
        router.push({ path: "/coupon",amount:props.amount });
      }
    }
    return {
      couponRecordInfo,
      repaymentCouponId,
      count,
      showPopover,
      guideVisible,
      guideType,
      choosePay,
      payList,
      payInfo,
      getPayInfo,
      copyNumber,
      showGuide,
      toUseCoupon,
    };
  },
};
</script>

<style lang="less" scoped>
.repayment-panel {
  margin: 20px 15px;

  &-list {
    width: 345px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    &-item {
      padding: 20px 0;
      border-bottom: 1px solid #f2f4f7;

      &:last-child {
        border: none;
      }
    }
  }

  &-title {
    margin: 0 0 17px;
    position: relative;
    color: #888f99;
    font-size: 16px;
    font-weight: bold;
    text-indent: 12px;

    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -3.5px;
      content: "";
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: @MainColor;
    }
  }

  &-pay {
    margin: 15px 0 0 0;
    padding: 15px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #130e3d;
    border-radius: 15px;
    background-color: #fff;

    &-avtive {
      color: #fff;
      background-color: #14533f;
    }

    &-icon {
      margin: 0 13px 0 0;
      width: 40px;
      height: 40px;
    }
  }

  &-empty {
    padding: 100px 0;
    font-size: 18px;
    font-weight: bold;
    color: #bfbfbf;
    line-height: 1.5;
    text-align: center;
  }

  &-payDetail {
    position: relative;
    margin: 14.5px 0 0;
    padding: 0 15px;
    border-radius: 15px;
    background-color: #fff;

    &-arrow {
      position: absolute;
      top: -9.5px;
      left: 47px;
      width: 21px;
      height: 9.5px;
    }

    &-item {
      width: 315px; // 弹出框宽度需要设置
      padding: 12px 0 20px;
      border-bottom: 1px solid #f2f4f7;

      &:last-child {
        border: none;
      }

      &-row {
        display: flex;
        align-items: center;
        color: #130e3d;
        font-size: 21px;
        font-weight: bold;

        &-coupon {
          margin: 0 0 0 auto;
          display: flex;
          align-items: center;

          &-amount {
            margin: 0 7.5px 0 auto;
            color: #F3841E;
          }

          &-arrow {
            width: 11px;
            height: 11px;
          }
        }

        &-icon {
          margin: 0 14.5px 0 0;
          width: 34px;
          height: 34px;
        }


        &-arrow {
          margin: 0 0 0 auto;
          width: 11px;
          height: 11px;
        }



        &-eidt {
          margin: 0 0 0 auto;
          width: 20px;
          height: 20px;
        }
      }

      &-code {
        margin: 17.5px 0 0 0;
        width: 100%;
        height: 81px;
      }

      &-codeText {
        margin: 10px 0 0;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
      }



      &-desc,
      &-desc-coupon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-indent: 49.5px;
        font-size: 13px;
        font-weight: bold;
        color: #888f99;

        &-copy {
          margin: 0 0 0 auto;
          padding: 2px 10.5px;
          font-size: 14px;
          font-weight: bold;
          text-indent: 0;
          color: #fff;
          border-radius: 12.5px;
          background: linear-gradient(to bottom, @MainColor, @SecondaryColor);

        }

        &-hint {
          font-size: 11px;
        }
      }

      &-desc-coupon {
        color: #F3841E;
      }
    }

    &-bank {
      width: 350px;
      padding: 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 19px;
      font-weight: bold;
      border-radius: 15px;
      background: linear-gradient(to top, @MainColor, @SecondaryColor);

      &-icon {
        width: 40px;
        height: 40px;
      }

      &-arrow {
        width: 11px;
        height: 11px;
      }
    }
  }
}
</style>