<template>
  <div class="bankCardDetail">
    <NavBack title="Recibiendo cuenta"></NavBack>
    <div class="bankCardDetail-card">
      <div class="bankCardDetail-card-row">
        <div class="bankCardDetail-card-row-title">Nombre</div>
        <div
          class="bankCardDetail-card-row-value"
          :class="{ 'bankCardDetail-card-row-value-active': !isReview }"
        >
          <input
            type="text"
            class="bankCardDetail-card-row-value-input"
            placeholder="Por favor elige"
            :disabled="isReview"
          />
        </div>
      </div>
      <div class="bankCardDetail-card-row">
        <div class="bankCardDetail-card-row-title">Tipo de cuenta</div>
        <div
          class="bankCardDetail-card-row-value"
          :class="{ 'bankCardDetail-card-row-value-active': !isReview }"
        >
          <input
            type="text"
            class="bankCardDetail-card-row-value-input"
            placeholder="Por favor elige"
            disabled
          />
          <img
            class="bankCardDetail-card-row-value-arrow"
            src="../../assets/icons/arrow_step1@3x.png"
          />
        </div>
      </div>
      <div class="bankCardDetail-card-row">
        <div class="bankCardDetail-card-row-title">Nombre del banco</div>
        <div
          class="bankCardDetail-card-row-value"
          :class="{ 'bankCardDetail-card-row-value-active': !isReview }"
        >
          <input
            type="text"
            class="bankCardDetail-card-row-value-input"
            placeholder="Por favor elige"
            disabled
          />
          <img
            class="bankCardDetail-card-row-value-arrow"
            src="../../assets/icons/arrow_step1@3x.png"
          />
        </div>
      </div>
      <div class="bankCardDetail-card-row">
        <div class="bankCardDetail-card-row-title">
          Número de tarjeta bancaria
        </div>
        <div
          class="bankCardDetail-card-row-value"
          :class="{ 'bankCardDetail-card-row-value-active': !isReview }"
        >
          <input
            type="text"
            class="bankCardDetail-card-row-value-input"
            placeholder="Por favor escribe"
            :disabled="isReview"
          />
        </div>
        <div class="bankCardDetail-card-row-hint">
          Ingrese el número de tarjeta bancaria correcto y confirme que sea
          correcto.
        </div>
      </div>
      <div class="bankCardDetail-card-row">
        <div class="bankCardDetail-card-row-title">
          Foto de tarjeta bancaria (opcional)
        </div>
        <div class="bankCardDetail-card-row-imgs">
          <div class="bankCardDetail-card-row-imgs-item"></div>
        </div>
      </div>
    </div>
    <div class="bankCardDetail-bottom">
      <van-button
        class="primary-btn"
        :disabled="isReview"
        round
        block
        @click="submit"
        >Someter</van-button
      >
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "BankCardDetail",
  setup() {
    const submit = function () {};
    const isReview = ref(true);
    const visibleRetainPopup = ref(false);
    const checked = ref("1");
    return {
      submit,
      isReview,
      visibleRetainPopup,
      checked,
    };
  },
};
</script>

<style lang="less" scoped>
.bankCardDetail {
  position: relative;
  padding: 0 0 100px 0;
  height: 100vh;
  overflow-y: scroll;
  background-color: #f2f4f7;
  &-card {
    margin: 14.5px 10px;
    padding: 20px 15px 6px;
    font-weight: bold;
    border-radius: 25px;
    background-color: #fff;
    &-row {
      margin: 0 0 20px;
      &-title {
        margin: 0 0 6px;
        font-size: 16px;
        color: #3f3d5a;
      }
      &-imgs {
        &-item {
          margin: 14px 0 0 0;
          width: 110px;
          height: 74px;
          border-radius: 8px;
          border: 1px solid #3ab795;
        }
      }
      &-hint {
        margin: 10px 0 0;
        padding: 10px 16px;
        font-size: 15px;
        color: #3ab795;
        border-radius: 8px;
        background-color: #e5f9f2;
      }
      &-value {
        padding: 10px 16px;
        height: 70px;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #888f99;
        border-radius: 8px;
        background-color: #f2f4f7;
        &-active {
          color: #130e3d;
        }
        &-input {
          background-color: transparent;
          border: none;
        }

        &-arrow {
          margin: 0 0 0 auto;
          width: 11px;
          height: 11px;
        }
        &-radio {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  &-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 7.5px 15px;
    background-color: #fff;
  }
}
</style>