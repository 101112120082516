<template>
  <div class="personalInfo">
    <NavBack title="Informacion personal"></NavBack>
    <div class="personalInfo-cards">
      <div
        class="personalInfo-cards-item"
        @click="$router.push('/identityInfo')"
      >
        <van-image
          class="personalInfo-cards-item-img"
          :src="require('../../assets/icons/icon_infor1@3x.png')"
        />
        <div>Identidad</div>
      </div>
      <div class="personalInfo-cards-item" @click="$router.push('/workInfo')">
        <van-image
          class="personalInfo-cards-item-img"
          :src="require('../../assets/icons/icon_infor2@3x.png')"
        />
        <div>Trabajo</div>
      </div>
      <div
        class="personalInfo-cards-item"
        @click="$router.push('/contactInfo')"
      >
        <van-image
          class="personalInfo-cards-item-img"
          :src="require('../../assets/icons/icon_infor3@3x.png')"
        />
        <div>Conexión</div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
  name: "PersonalInfo",
  setup() {
    const store = useStore();
    onMounted(() => {
      store.dispatch("getUserInfo");
    });
    return {};
  },
};
</script>
<style lang="less" scoped>
.personalInfo {
  height: 100vh;
  background-color: #f2f4f7;
  &-cards {
    padding: 0 15px;
    &-item {
      margin: 15px 15px 15px 0;
      padding: 22px 42px;
      display: inline-block;
      color: #130e3d;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      border-radius: 25px;
      background-color: #fff;
      &:nth-child(2n) {
        margin-right: 0;
      }
      &-img {
        margin: 0 0 16px 0;
        width: 80px;
        height: 65px;
      }
    }
  }
}
</style>