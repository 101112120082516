<template>
<div class="logoutTip">
 <h1>Recordatorio importante</h1>
  <p>
    Cerrar sesión en una cuenta es una acción irreversible. Antes de operar,
    confirme que todos los servicios relacionados con la cuenta se hayan
    manejado correctamente.
  </p>
  <p>
    Recuerde: si cancela su cuenta, ya no podrá usar ningún contenido o
    información en esta cuenta (incluso si usa el mismo número de teléfono móvil
    para registrarse y usar WeChat nuevamente), incluidos, entre otros, los
    siguientes:
  </p>
  <ul>
    <li>(1) No podrá iniciar sesión ni utilizar esta cuenta.</li>
    <li>(2) Datos personales e información histórica de su cuenta</li>
    <li>
      (3) Antes de cancelar la cuenta, debe liquidar el pedido realizado en la
      cuenta. Debe manejar adecuadamente todos los problemas de financiación en
      su cuenta.
    </li>
  </ul>
  <p>
    Tenga en cuenta que la cancelación de su cuenta no significa que el
    comportamiento de la cuenta y las responsabilidades relacionadas antes de la
    cancelación de la cuenta estén exentos o mitigados.
  </p>
</div>
 
</template>
<style lang="less">
.logoutTip{
    font-size: 16px;
    padding: 20px;
    h1{
        padding: 10px 0;
        font-weight: bold;
        text-align: center;
    }
}
</style>