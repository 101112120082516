const appConfig = {
    platform: navigator.userAgent.toUpperCase().indexOf('ANDROID') > -1 ? '1':'2', //平台类型,1:Android 2:IOS
    appId: "21", // app马甲包 4:Qcash 5:proash 21:moxige
    appName: "okpresta",
    packageName: "com.mexico.okpresta",
    appVersion: "1.0.0", // app版本号
    appBuild: "001",
    appChannel: "apple_1", // app渠道号
}

export default appConfig