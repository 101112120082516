<template>
  <div class="loading">
   <van-loading size="24px" vertical> ...</van-loading>
  </div>
</template>

<script>
import { Loading } from 'vant';
export default {
  components:{
    [Loading.name]:Loading
  }
};
</script>

<style scoped lang="less">
.loading {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}
</style>
