import { getQuery } from './tools'

export function getParams() {
  let applicationId = getQuery('applicationId')
  applicationId && sessionStorage.setItem('applicationId', applicationId)
  let appVersion = getQuery('appVersion')
  appVersion && sessionStorage.setItem('appVersion', appVersion)
  let couponId = getQuery('couponId')
  couponId && sessionStorage.setItem('couponId', couponId)
  let couponRecordId = getQuery('couponRecordId')
  couponRecordId && sessionStorage.setItem('couponRecordId', couponRecordId)
  let isReceived = getQuery('isReceived')
  isReceived && sessionStorage.setItem('isReceived', isReceived)
  let landPageId = getQuery('landPageId')
  landPageId && sessionStorage.setItem('landPageId', landPageId)
}
