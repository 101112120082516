<template>
  <NavBack title="Ajustes y Acuerdo"></NavBack>
  <div class="accountMgr">
    <div class="accountMgr-card">
      <div class="accountMgr-card-list">
        <div class="accountMgr-card-list-item"
          @click="openPage('https://product.suprestamo-online.com/agreement/appLoanContract.html')">
          <span>Acuerdo de prestamo</span>
          <img class="accountMgr-card-list-item-arrow" src="../../assets/icons/arrow_step1@3x.png" alt="">
        </div>
        <div class="accountMgr-card-list-item"
          @click="openPage('https://product.suprestamo-online.com/agreement/agreement.html')">
          <span>Acuerdo de privacidad</span>
          <img class="accountMgr-card-list-item-arrow" src="../../assets/icons/arrow_step1@3x.png" alt="">
        </div>
        <div class="accountMgr-card-list-item" @click="$router.push('logout')">
          <span>Cancelar la cuenta</span>
          <img class="accountMgr-card-list-item-arrow" src="../../assets/icons/arrow_step1@3x.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
   setup() {
        const openPage = (url) => {
            window.location.href = url
        }
        return {
            openPage
        }
    }
};
</script>

<style lang="less" scoped>
.accountMgr {
  padding: 20px 0 0;
  height: calc(100vh - 72px);
  box-sizing: border-box;
  background-color: #f3f5f9;

  &-card {
    margin: 0 15px;
    border-radius: 20px;
    background-color: #fff;

    &-list {
      padding: 10px;

      &-item {
        padding: 10px 0;
        display: flex;
        align-items: center;
        color: #5E5D6E;
        font-size: 16px;
        font-weight: bold;
        font-weight: 500;

        &:last-child {
          border-bottom: none;
        }

        &-icon {
          margin-right: 11.5px;
          width: 21px;
          height: 21px;
        }

        &-arrow {
          margin-left: auto;
          width: 11px;
          height: 11px;
        }
      }
    }
  }
}
</style>