<template>
  <!-- 开启顶部安全区适配 -->
  <!-- <van-nav-bar safe-area-inset-top /> -->
  <van-config-provider :theme-vars="themeVars">
    <load v-show="isLoading"></load>
    <router-view />
  </van-config-provider>
  <!-- 开启底部安全区适配 -->
  <!-- <van-number-keyboard safe-area-inset-bottom /> -->
</template>
<script>
import { ConfigProvider } from "vant";
import load from "@/components/Loading/Loading.vue";
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  components: { [ConfigProvider.name]: ConfigProvider, load },
  setup() {
    const themeVars = {
      overlayBackgroundColor: "rgba(11, 13, 8, 0.5)",
      popupBackgroundColor: "transparent",
      buttonBorderRadius: "8px",
    };
    const store = useStore();
    console.log("app");

    const isLoading = computed(() => store.state.isLoading);

    return {
      isLoading,
      themeVars,
    };
  },
};
</script>
<style lang="less">
@import "@/assets/styles/variables.less";

* {
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: none !important;
}

.font-bold {
  font-weight: bold;
}

.primary-btn {
  min-width: 125px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  color: #fff;
  font-size: 19px;
  font-weight: bold;
  background: linear-gradient(to bottom, @SecondaryColor, @MainColor)
}

.default-btn {
  min-width: 125px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  // border-radius: 8px;
  color: #5e5d6e;
  font-size: 19px;
  font-weight: bold;
  background-color: #f2f4f7;
}
</style>
