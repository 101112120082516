<template>
  <div class="repaymentDetail">
    <NavBack title="Detalles del préstamo"></NavBack>
    <div class="repaymentDetail-card">
      <div class="repaymentDetail-card-row">
        <div>Monto del préstamo</div>
        <div class="repaymentDetail-card-row-value">
          ${{ $thousands(applicationInfo.loanAmount) }}
        </div>
      </div>
      <div class="repaymentDetail-card-row">
        <div>Plazo del préstamo</div>
        <div class="repaymentDetail-card-row-value">
          {{ applicationInfo.loanTimeLimit }}dias
        </div>
      </div>
      <div class="repaymentDetail-card-row">
        <div>
          Intereses
          <span>{{
            [2, 6, 5, 7].includes(status) ? " (prepago)" : " (recibido)"
          }}</span>
        </div>
        <div class="repaymentDetail-card-row-value">
          ${{ $thousands(applicationInfo.interest) }}
        </div>
      </div>
      <!-- 前置服务费 -->
      <div class="repaymentDetail-card-row">
        <div>
          Tarifa de auditoría
          <span>{{
            [2, 6, 5, 7].includes(status) ? " (prepago)" : " (recibido)"
          }}</span>
        </div>
        <div class="repaymentDetail-card-row-value">
          ${{ $thousands(applicationInfo.auditServiceExpense) }}
        </div>
      </div>
      <van-divider />
      <template v-if="[0, 1, 3, 4].includes(status)">
        <template v-if="[1, 4].includes(status)">
          <div class="repaymentDetail-card-row">
            <div>Tarifas vencidas</div>
            <div class="
                repaymentDetail-card-row-value
                repaymentDetail-card-row-value-red
              ">
              ${{ $thousands(applicationInfo.orderInfo.overduePayment) }}
            </div>
          </div>
          <div class="repaymentDetail-card-row">
            <div>Dias atrasados</div>
            <div class="
                repaymentDetail-card-row-value
                repaymentDetail-card-row-value-red
              ">
              {{ applicationInfo.orderInfo.overdueDays }}dias
            </div>
          </div>
        </template>
        <!-- 后置服务费 -->
        <div class="repaymentDetail-card-row">
          <div>Tarifa de servicio</div>
          <div class="repaymentDetail-card-row-value">
            ${{ $thousands(applicationInfo.afterAuditService) }}
          </div>
        </div>
        <div class="repaymentDetail-card-row">
          <div>Importe total a pagar</div>
          <div class="repaymentDetail-card-row-value">
            ${{ $thousands(applicationInfo.orderInfo.overdueAmount) }}
          </div>
        </div>
        <div class="repaymentDetail-card-row">
          <div>Descuento cupón</div>
          <div class="repaymentDetail-card-row-value repaymentDetail-card-row-value-primary">
            <!-- 优惠券金额 -->
            -${{ $thousands(applicationInfo.orderInfo?.couponAmount) }}
          </div>
        </div>
        <div class="repaymentDetail-card-row">
          <div>Cantidad pagada</div>
          <div class="repaymentDetail-card-row-value">
            ${{ $thousands(applicationInfo.orderInfo.alreadyRepaymentAmount) }}
          </div>
        </div>
        <template v-if="[0, 1].includes(status)">
          <van-divider />
          <div class="repaymentDetail-card-row">
            <div>
              <span>Fecha de liquidación</span>
            </div>
            <div class="
                repaymentDetail-card-row-value
                repaymentDetail-card-row-value-red
              ">
              {{ $dayjs(applicationInfo.finishTime).format("DD-MM-YYYY") }}
            </div>
          </div>
        </template>
        <template v-else>
          <van-divider />
          <div class="repaymentDetail-card-row">
            <div>Importe pendiente restante</div>
            <div class="
                repaymentDetail-card-row-value
                repaymentDetail-card-row-value-red
              ">
              ${{ $thousands(applicationInfo.orderInfo.repaymentRemainAmount) }}
            </div>
          </div>
          <div class="repaymentDetail-card-desc">
            La agencia de crédito registrará los pagos atrasados; reembolse lo
            antes posible.
          </div>
        </template>
      </template>
      <template v-if="[2, 6, 7].includes(status)">
        <div class="repaymentDetail-card-row">
          <div>Cantidad recibida</div>
          <div class="
              repaymentDetail-card-row-value repaymentDetail-card-row-value-red
            ">
            ${{ $thousands(applicationInfo.accountAmount) }}
          </div>
        </div>
        <div class="repaymentDetail-card-row">
          <!-- 申请时间 -->
          <div>Fecha de aplicación</div>
          <div class="repaymentDetail-card-row-value">
            {{ $dayjs(applicationInfo.applicationTime).format("DD-MM-YYYY") }}
          </div>
        </div>
      </template>

      <div v-if="status == 5" class="repaymentDetail-card-row">
        <div>Fecha de rechazo</div>
        <div class="
            repaymentDetail-card-row-value repaymentDetail-card-row-value-red
          ">
          {{ $dayjs(applicationInfo.refuseTime).format("DD-MM-YYYY") }}
        </div>
      </div>
    </div>
    <div v-if="[3, 4].includes(status)" class="repaymentDetail-action">
      <div class="repaymentDetail-action-coupon">
        <div class="repaymentDetail-action-coupon">
          <div class="repaymentDetail-action-coupon-text">
            <img class="repaymentDetail-action-coupon-icon" src="../../assets/icons/icon_cupon@3x.png" alt="" srcset="">
            <span>Tienes {{ count }} cupones para usar</span>
          </div>
        </div>
      </div>
      <van-button class="primary-btn" block @click="$router.push('/repayment')">Pagar inmediatamente</van-button>
    </div>
  </div>
</template>
<script>
import NavBack from "@/components/NavBack";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { getApplicationInfo, latest } from "@/api/order.js";
import { canUseCouponNum } from "@/api/coupon.js";
import { getOrderStatus } from "@/utils/tools.js";
import { Divider } from "vant";
export default {
  name: "RepaymentDetail",
  components: { NavBack, [Divider.name]: Divider },
  setup() {
    const route = useRoute();
    let applicationInfo = ref({
      loanAmount: 0,
      loanTimeLimit: 0,
      interest: 0,
      auditServiceExpense: 0,
      sRepaymentAmount: 0,
      orderInfo: {
        overdueDays: 0,
        repaymentRemainAmount: 0,
        overdueAmount: 0,
        overduePayment: 0,
      },
    });
    const type = ref(1);
    // let status = ref(); // 0 已完成 1逾期完成， 2 申请中， 3 还款中， 4 逾期中， 5 拒绝
    let status = ref(5); // 0 已完成 1逾期完成， 2 申请中， 3 还款中， 4 逾期中， 5 拒绝,6 放款中 7 放款失败
    let count = ref(0)
    let applicationId = sessionStorage.getItem("applicationId");

    onMounted(async () => {
      if (applicationId) {
        getOrder(); // app传入或者其他页面回退
      } else {
        getData(); // 获取最新信息
      }
    });
    async function getNum() {
      count.value = await canUseCouponNum({ amount: applicationInfo.value.sRepaymentAmount });
    }

    async function getOrder(id) {
      const res = await getApplicationInfo({
        applicationId: id || applicationId || route.query.applicationId,
      });
      status.value = getOrderStatus(res.status);

      applicationInfo.value = res;
      await getNum()

    }
    async function getData() {
      const res = await latest();
      if (res?.orderInfo) {
        getOrder(res.id);
        sessionStorage.setItem("applicationId", res.id);
        await getNum()
      }
    }
    return {
      applicationInfo,
      type,
      status,
      count,
    };
  },
};
</script>
<style lang="less" scoped>
.repaymentDetail {
  height: 100vh;
  padding: 0 0 110px 0;
  background-color: #f2f4f7;
  overflow-y: scroll;

  &-card {
    margin: 15px;
    padding: 15px;
    border-radius: 25px;
    background-color: #fff;

    &-row {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #888f99;
      font-size: 14px;
      font-weight: bold;

      &-value {
        font-size: 16px;
        color: #14171b;

        &-red {
          color: #e34443;
        }

        &-primary {
          color: @MainColor;
        }
      }
    }

    &-desc {
      margin: 12px 0 0;
      padding: 20px;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5;
      color: #888f99;
      border-radius: 15px;
      background-color: #f2f4f7;
    }
  }

  &-action {
    position: fixed;
    bottom: 0;
    padding: 7.5px 15px;
    width: 100%;
    background-color: #fff;

    &-coupon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 0;
      font-size: 14px;
      color: #F3841E;
      word-break: break-all;
      background-color: #fff;

      &-icon {
        display: inline-block;
        margin: 0 10.5px 0 0;
        width: 20px;
        height: 20px;
        vertical-align: sub;
      }
    }
  }
}
</style>
