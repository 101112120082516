export function downloadImage(imgsrc, name) {
  let a = document.createElement('a')
  a.download = name
  a.href = imgsrc
  a.dispatchEvent(new MouseEvent('click'))
}
export function thousands(number) {
  const num = Number(number)
  if (num) {
    return num.toLocaleString('en-US')
  } else {
    return number
  }
}
export function getQuery(name) {
  const w = location.hash.indexOf('?')
  const query = location.hash.substring(w + 1)

  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pari = vars[i].split('=')
    if (pari[0] == name) {
      return pari[1]
    }
  }
  return false
}
export function copyText(text) {
  if (navigator.clipboard) {
    // clipboard api 复制
    navigator.clipboard.writeText(text)
  } else {
    const u = navigator.userAgent
    var isAndriod = u.toUpperCase().indexOf('ANDROID') > -1
    var isIOS = u.toUpperCase().indexOf('IOS') > -1

    var aux = document.createElement('input')
    aux.setAttribute('value', text)
    aux.setAttribute('disabled', 'disabled')
    aux.readOnly = true // 防止ios 手机弹出键盘框
    document.body.appendChild(aux)

    console.log('agent', u, 'isIOS', isIOS)

    if (!isAndriod) {
      window.getSelection().removeAllRanges() //将页面所有的文本区域都从选区中移除
      var range = document.createRange() //创建一个文本区域
      range.selectNode(aux) //将我们的所选节点添加到文本区域中
      window.getSelection().addRange(range) //将文本区域添加至选区中
      document.execCommand('copy') //执行浏览器的复制命令
      window.getSelection().removeAllRanges() //最后再移除选区中的所有文本区域
    } else {
      aux.select()
      document.execCommand('copy')
      document.body.removeChild(aux)
    }
  }
}
export function separate(value) {
  if (/\S{5}/.test(value)) {
    return value.replace(/\s/g, '').replace(/(.{4})/g, '$1 ')
  } else {
    return value
  }
}
export function trimAll(str) {
  str = String(str)
  return str.replace(/\s+/g, '')
}
// 0 已完成 1逾期完成， 2 申请中， 3 还款中， 4 逾期中， 5 拒绝
export function getOrderStatus(status) {
  let s = 0
  switch (status) {
    case 10: // 正常完成
      s = 0
      break
    case 11: // 异常完成
      s = 1
      break
    case 2: // 申请中
    case 16: // 初审中
    case 17: // 复审中
    case 22: // 回退再申请
      s = 2
      break
    case 6: // 放款成功
    case 19: // 还款中
    case 20: // 预催收中
      s = 3
      break
    case 21: // 逾期中，催收中
      s = 4
      break
    case 1: // 已注册
    case 4: // 回退中
    case 14: // 放弃取款
    case 5: // 已拒绝
      s = 5
      break
    case 3: // 已通过（未放款）
      s = 6
      break
    case 18: // 支付失败
      s = 7
      break
    case 15: // 放弃到期
    case 7: // 拒绝到期
    case 23: // 取消
      s = 8
      break
    default:
      s = 0
      break
  }
  return s
}
