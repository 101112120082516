<template>
  <label for="code">
    <div class="input-panel">
      <div
        v-for="(item, index) in number"
        :key="index"
        :class="[
          'input-panel-item',
          (index === codes.length - 1 && codes.length != number) ||( codes.length == 6 && index == 5)
            ? 'input-panel-item-active'
            : '',
          codes.length == 0 && index == 0
            ? 'input-panel-item-empty-active'
            : '',
        ]"
      >
        <div>{{ codes[index] || "" }}</div>
      </div>
    </div>
  </label>
  <input
    ref="inputEl"
    class="input-code"
    v-model="codes"
    id="code"
    name="code"
    type="tel"
    :maxlength="number"
    autocorrect="off"
    autocomplete="off"
    autocapitalize="off"
  />
</template>

<script>
import { ref, watch } from "vue";
export default {
  name: "sms_code_input",
  props: {
    code: { type: String, default: "" },
    number: {
      type: Number,
      default: 4,
    },
  },
  setup(props, { emit }) {
    let codes = ref("");
    let inputEl = ref(null);
    watch(
      () => codes.value,
      (val) => {
        emit("update:code", val);
        if (val.length == props.number) {
          // 输入完成隐藏键盘
          document.activeElement.blur(); // ios隐藏键盘
          inputEl.value.blur(); // android隐藏键盘
        }
      }
    );
    return {
      inputEl,
      codes,
    };
  },
};
</script>

<style lang="less" scoped>
.input-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-item {
    width: 45px;
    height: 45px;
    font-size: 33px;
    font-weight: bold;
    line-height: 45px;
    text-align: center;
    border-bottom: 1px solid #EBEDF0;
    // border-radius: 10px;
    &-active {
      color: @MainColor;
      border-bottom: 1px solid @MainColor;
    }
    &-empty-active {
      border-bottom: 1px solid @MainColor;
    }
  }
}
.input-code {
  position: absolute;
  left: -9999px;
  top: -99999px;
  opacity: 0;
  overflow: visible;
  z-index: -1;
}
</style>
