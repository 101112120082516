import request from '@/utils/request'

export async function login(params) {
    return request({
        url: '/login/signIn',
        method: 'get',
        params,
    })
}

export async function requestVerifyCode(params) {
    return request({
        url: '/login/requestVerifyCode',
        method: 'get',
        params,
    })
}
// 用户信息
export async function userBasisInfo(params) {
    return request({
        url: '/basisInfo/load',
        method: 'get',
        params,
    })
}
// 数据字典
export async function findDictInfo(params) {
    return request({
        url: '/dict/findDictInfo',
        method: 'get',
        params,
    })
}
// 注销
export async function logOut() {
    return request({
        url: '/login/logOut',
        method: 'put',
    })
}
// 用户基本信息
export async function load() {
    return request({
        url: '/basisInfo/load',
        method: 'get',
    })
}