import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '@/views/Home.vue'

import Login from '@/views/user/Login.vue'
import SmsCode from '@/views/user/SmsCode.vue'
import repayment from '@/views/repayment/index.vue'
import repaymentDetail from '@/views/repayment/detail.vue'
import personalInfo from '@/views/personalInfo/index.vue'
import contact from '@/views/personalInfo/contact.vue'
import workInfo from '@/views/personalInfo/workInfo.vue'
import contactInfo from '@/views/personalInfo/contactInfo.vue'
import identityInfo from '@/views/personalInfo/identityInfo.vue'
import bankCard from '@/views/bankCard/index.vue'
import bankCardDetail from '@/views/bankCard/detail.vue'
import logout from '@/views/system/logout.vue'
import accountMgr from '@/views/system/accountMgr.vue'
import logoutTips from '@/views/system/logoutTips.vue'
import coupon from '@/views/coupon/list.vue'
import couponDetail from '@/views/coupon/detail.vue'
import receiveCoupon from '@/views/receive/coupon.vue'
import apply from './apply'
import home from '@/views/home/index.vue'

import { getQuery } from '@/utils/tools.js'
import { getParams } from '@/utils/session'
import store from '../store'

import thirdPage from '@/views/third/thirdPage.vue'

const routes = [
  {
    path: '/',
    component: home,
    meta: { title: 'OKprésta' }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'OKprésta'
    }
  },
  {
    path: '/SmsCode',
    name: 'SmsCode',
    component: SmsCode,
    meta: {
      title: 'Código de verificación'
    }
  },
  ...apply,
  {
    path: '/home',
    component: home,
    meta: { title: 'OKprésta' }
  },
  {
    path: '/repayment',
    name: 'Repayment',
    component: repayment,
    meta: {
      title: 'Reembolso'
    }
  },
  {
    path: '/repaymentDetail',
    name: 'repaymentDetail',
    component: repaymentDetail,
    meta: {
      title: 'Detalles de reembolso'
    }
  },
  {
    path: '/personalInfo',
    name: 'personalInfo',
    component: personalInfo,
    meta: {
      title: 'Informacion personal'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact,
    meta: {
      title: 'Contáctenos'
    }
  },
  {
    path: '/workInfo',
    name: 'workInfo',
    component: workInfo,
    meta: {
      title: 'Información de trabajo'
    }
  },
  {
    path: '/contactInfo',
    name: 'contactInfo',
    component: contactInfo,
    meta: {
      title: 'Contacto'
    }
  },
  {
    path: '/identityInfo',
    name: 'identityInfo',
    component: identityInfo,
    meta: {
      title: 'Autenticación'
    }
  },
  {
    path: '/bankCard',
    name: 'bankCard',
    component: bankCard,
    meta: {
      title: 'Tarjeta bancaria'
    }
  },
  {
    path: '/bankCardDetail',
    name: 'bankCardDetail',
    component: bankCardDetail,
    meta: {
      title: 'Detalles de la tarjeta bancaria'
    }
  },
  {
    path: '/coupon',
    name: 'coupon',
    component: coupon,
    meta: {
      title: 'Mi cupón'
    }
  },
  {
    path: '/couponDetail',
    name: 'couponDetail',
    component: couponDetail,
    meta: {
      title: 'Detalles del cupón'
    }
  },
  {
    path: '/receiveCoupon',
    name: 'receiveCoupon',
    component: receiveCoupon,
    meta: {
      title: 'Detalles del cupón'
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: logout,
    meta: {
      title: 'Cancelar la cuenta'
    }
  },
  {
    path: '/logoutTips',
    name: 'logoutTips',
    component: logoutTips,
    meta: {
      title: 'Recordatorio importante'
    }
  },
  {
    path: '/accountMgr',
    name: 'accountMgr',
    component: accountMgr,
    meta: {
      title: 'Ajustes y Acuerdo'
    }
  },
  {
    path: '/thirdPage',
    name: 'thirdPage',
    component: thirdPage,
    meta: {
      title: 'thirdPage'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
store.dispatch('getDict')

router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('token')
  getParams()
  if (!['Login', 'SmsCode'].includes(to.name)) {
    if (!token) {
      token = getQuery('token')
      let applicationId = getQuery('applicationId')
      if (applicationId) {
        sessionStorage.setItem('applicationId', applicationId)
      } else {
        sessionStorage.removeItem('applicationId')
      }
      let appVersion = getQuery('appVersion')
      appVersion && sessionStorage.setItem('appVersion', appVersion)
      if (token) {
        sessionStorage.setItem('token', token)
        router.push(to.name)
      } else {
        store.commit('setRedirect', to.fullPath)
        router.push({ path: '/Login' })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})
router.afterEach((to) => {
  document.title = to.meta.title
})
export default router
