<template>
  <div class="coupon">
    <NavBack title="Mi cupón"></NavBack>
    <div class="tab">
      <div class="tab-item" :class="{ 'tab-item-active': status == 1 }" @click="onClickTab(1)">
        No usado
        <img v-if="status == 1" class="tab-item-icon" src="../../assets/icons//tab_cupon@3x.png" alt="">
      </div>
      <div class="tab-item" :class="{ 'tab-item-active': status == 2 }" @click="onClickTab(2)">
        Inválido
        <img v-if="status == 2" class="tab-item-icon" src="../../assets/icons//tab_cupon@3x.png" alt="">
      </div>
    </div>
    <div class="coupon-list" :class="{ 'invalid-list': status == 2 }">
      <div class="coupon-list-item coupon-list-item-row" :class="{ 'coupon-list-item-in': status == 2 }"
        v-for="(item, index) in list" :key="index" @click="toDetail(item)">
        <div>
          <div class="coupon-list-item-row">
            <div class="coupon-list-item-number">${{ $thousands(item.discountAmount) }}</div>
            <img class="coupon-list-item-check" v-show="status == 1" @click.stop="chooed(item)" :src="item.isCheck
              ? require('../../assets/icons/select_agreement@3x.png')
              : require('../../assets/icons/n_select_agreement@3x.png')
              " />
          </div>
          <div class="coupon-list-item-name">
            {{ item.repaymentCoupon.name }}
            <img class="coupon-list-item-name-arrow" src="../../assets/icons/arrow_step1@3x.png" />
          </div>
          <div class="coupon-list-item-time">
            <span> Período de validez: </span>
            <span> {{ item.startUsableTime }}-{{ item.endUsableTime }} </span>
          </div>
        </div>
        <div class="coupon-list-item-tag">
          <span>Usar</span>
        </div>
      </div>
    </div>
    <div v-show="status == 1" class="coupon-action">
      <div class="coupon-action-box">
        <div class="coupon-action-coupon">
          <div class="coupon-action-coupon-text">
            <img class="coupon-action-coupon-icon" src="../../assets/icons/icon_cupon@3x.png" alt="" srcset="">
            <span>Ofertas de cupones</span>
          </div>
          <div>
            -${{ $thousands(chooseCoupon?.discountAmount) }}
          </div>
        </div>
        <div class="coupon-action-btn" @click="toRepayment">Confirmar uso</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onBeforeUnmount, onBeforeMount } from "vue";
import { findCoupon } from "@/api/coupon.js";
import router from '@/router'
import { Toast } from "vant";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute()
    const status = ref(1);
    const list = ref([]);
    const chooseCoupon = ref();
    const loading = ref(false);
    const finished = ref(false);
    const pages = ref({
      page: 1,
      pageSize: 10,
    });
    const onClickTab = (e) => {
      status.value = e;
      pages.value.page = 1;
      finished.value = false;
      list.value = [];
      onLoad();
    };
    const toDetail = (item) => {
      sessionStorage.setItem("couponRecordId", item.id);
      router.push("/couponDetail");
    };
    const onLoad = async () => {
      let _data = {
        amount: route.query.amount,
        status: status.value,
        page: pages.value.page,
        pageSize: pages.value.pageSize,
      };
      const res = await findCoupon(_data);
      pages.value.page++;
      // 加载状态结束
      loading.value = false;
      if (res.length) {
        list.value = pages.value.page > 1 ? list.value.concat(res) : res;
      } else {
        finished.value = true;
      }
      // 获取上次选中优惠券
      chooseCoupon.value = list.value.find(i => i.isCheck)
    };
    const chooed = (item) => {
      chooseCoupon.value = item;
      list.value.map(i => {
        if (i.id == item.id) {
          i.isCheck = true
        } else {
          i.isCheck = false
        }
      })
    };
    const toRepayment = () => {
      if (!chooseCoupon.value) {
        Toast("Por favor seleccione un cupón");
        return;
      }
      sessionStorage.setItem("repaymentCouponId", chooseCoupon.value.id); // 优惠券领取id
      router.push({ path: "/repayment" });
    };
    onBeforeMount(() => {
      onLoad();
    });
    onBeforeUnmount(() => {
      // 没有选择清除记录
      if (!chooseCoupon.value) {
        sessionStorage.removeItem("repaymentCouponId");
      }
    });
    return {
      status,
      chooseCoupon,
      onClickTab,
      list,
      onLoad,
      loading,
      finished,
      chooed,
      toRepayment,
      toDetail,
    };
  },
};
</script>
<style lang="less" scoped>
.coupon {
  min-height: 100vh;
  background-color: #F3F2F9;

  .tab {
    position: sticky;
    top: 70px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 44px;
    text-align: center;
    border-radius: 12px;
    z-index: 9;
    background: #F3F2F9;

    .tab-item {
      position: relative;
      width: 165px;
      color: #5E5D6E;
      font-size: 16px;
      font-weight: bold;

      &-icon {
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        width: 10px;
        height: 5.5px;
      }

      &:first-child {
        margin: 0 14.5px 0 0;
      }

      &-active {
        color: @MainColor;
      }
    }
  }

  &-list {
    padding: 15px 15px 100px;
    font-weight: bold;
    overflow-y: scroll;

    &-item {
      margin: 0 0 20px;
      padding: 16px 8.5px 16px 20.5px;
      overflow: hidden;
      background: url("../../assets/images/coupon_bg.png");
      background-size: 100% 100%;

      &-tag {
        width: 48px;
        height: 48px;
        line-height: 48px;
        font-size: 14px;
        color: @MainColor;
        text-align: center;
        border-radius: 50%;
        background-color: #fff;
      }

      &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-number {
        font-size: 33px;
        color: #14171B;
      }

      &-check {
        width: 20px;
        height: 20px;
      }

      &-name {
        width: 236px;
        display: flex;
        align-items: center;
        color: #81939B;
        font-size: 14px;

        &-arrow {
          margin: 0 0 0 7.5px;
          width: 11px;
          height: 11px;
        }
      }

      &-time {
        margin: 18px 0 0;
        font-size: 12px;
        color: #259572;
      }

      &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-text {
          margin: 0 12px 0 0;
          padding: 10px 0 14px 0;
          flex: 1;
          color: @MainColor;
          font-size: 28px;
          border-bottom: 1px dashed rgba(0, 0, 0, 0.1);

          &-desc {
            color: #333;
            font-size: 12px;
          }
        }

        &-icon {
          width: 20px;
          height: 20px;
        }

        &-detail {
          color: #999999;
          font-size: 10px;

          &-arrow {
            display: inline-block;
            width: 4px;
            height: 8px;
          }
        }

        &-time {
          color: #999999;
          font-size: 12px;
        }
      }

    }

    &-item-in {
      background: url("../../assets/images/coupon_bg_in.png");
      background-size: 100% 100%;

      .coupon-list-item-info {

        &-text,
        &-text-desc {
          color: #bbb;
        }
      }
    }
  }

  .invalid-list {
    height: 100%;
    padding: 15px;
    margin-bottom: 0;
  }

  .coupon-action {
    position: fixed;
    bottom: 0;
    padding: 8px 20px 10px;
    width: 100%;
    font-weight: bold;
    z-index: 9;
    background: #fff;

    &-coupon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 0;
      font-size: 14px;
      color: #F3841E;
      word-break: break-all;
      background-color: #fff;

      &-icon {
        display: inline-block;
        margin: 0 10.5px 0 0;
        width: 20px;
        height: 20px;
        vertical-align: sub;
      }

      &-number {
        color: @MainColor;
        font-size: 16px;
        font-weight: 600;
      }
    }

    &-btn {
      height: 55px;
      line-height: 55px;
      font-size: 16px;
      text-align: center;
      border-radius: 8px;
      color: #fff;
      background-color: @MainColor;
      background: linear-gradient(to left, @MainColor, @SecondaryColor);
    }
  }
}
</style>