<template>
  <NavBack title="Detalles del cupón"></NavBack>
  <div class="couponDetail">
    <div class="couponDetail-box" :class="{ 'invalid-bg': [1, -1, -2].includes(Number(info.status)) }">
      <div class="couponDetail-top">
        <div class="couponDetail-top-title">
          {{ info.repaymentCoupon.name }}
        </div>
        <div class="couponDetail-top-number">
          ${{ $thousands(info.repaymentCoupon?.discountAmount) }}
        </div>
      </div>
      <div class="couponDetail-list">
        <div class="couponDetail-list-title">Tiempo de uso</div>
        <div class="couponDetail-list-content">
          <span v-if="info.repaymentCoupon.validType == 1">
            {{ info.repaymentCoupon?.startUsableDate }} -
            {{ info.repaymentCoupon?.endUsableDate }}</span>
          <span v-if="info.repaymentCoupon.validType == 2">Caduca después de {{ info.repaymentCoupon?.receiveDay }} días
            a
            partir de la fecha de recolección</span>
        </div>
        <div class="couponDetail-list-title">Instrucciones de uso</div>
        <div class="couponDetail-list-content">
          {{ info.repaymentCoupon?.descExplain }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, onBeforeUnmount } from "vue";
import { getCouponRecordById } from "@/api/coupon.js";
export default {
  setup() {
    const couponRecordId = sessionStorage.getItem("couponRecordId");
    const info = ref({
      status: 2,
      name: "",
      discountAmount: 0,
      startUsableDate: null,
      endUsableDate: null,
      descExplain: "",
      repaymentCoupon: {},
    });

    const getInfo = async () => {
      const res = await getCouponRecordById(couponRecordId);
      info.value = res;
    };
    onMounted(() => {
      getInfo();
    });
    onBeforeUnmount(() => {
      sessionStorage.removeItem("couponRecordId");
    });
    return { info };
  },
};
</script>
<style lang="less" scoped>
.couponDetail {
  position: relative;
  padding: 15px 0 10px;
  font-weight: bold;
  min-height: calc(100vh - 70px);
  background-color: #F3F2F9;

  &-box {
    position: relative;
    margin: 15px;
    min-height: 400px;
    border-radius: 15px;
    background-color: #fff;
    overflow: visible;
  }


  &-top {
    padding: 30px 0;
    font-size: 19px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    background: url('../../assets/images/bg_detail1@3x.png');
    background-size: 100% 100%;

    &-number {
      margin: 10px 0 0;
      font-size: 55px;
    }
  }

  &-list {
    padding: 35px 27.5px;

    &-title {
      position: relative;
      text-indent: 16px;
      font-size: 18px;
      color: #212750;

      &::before {
        position: absolute;
        top: 40%;
        left: 0;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: @MainColor;
      }
    }

    &-content {
      margin: 8px 0 35px 0;
      color: #81939B;
      font-size: 16px;
      line-height: 2;
      font-weight: bold;
      word-break: break-all;

      &:last-child {
        margin: 10px 0 0;
        min-height: 144px;
      }
    }
  }

  &-action {
    position: absolute;
    bottom: 0;
    padding: 7.5px 15px;
    width: 100%;
    background-color: #fff;
  }
}

.invalid-bg {
  .couponDetail-top {
    background: url('../../assets/images/bg_detail2@3x.png');
    background-size: 100% 100%;
  }

  .couponDetail-list-title {
    &::before {
      background-color: #C8D0E0;
    }
  }

}
</style>