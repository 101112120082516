import request from '@/utils/request'

// 可用优惠券数量
export async function canUseCouponNum(params) {
    return request({
        url: `/coupon_new/canUseCouponNum`,
        method: 'get',
        params
    })
}
// 优惠券列表
export async function findCoupon(params) {
    return request({
        url: `/coupon_new/findCoupon`,
        method: 'get',
        showLoading:false,
        params
    })
}
// 领取优惠券
export async function receiveCoupon(data) {
    return request({
        url: `/coupon_new/receiveCoupon`,
        method: 'post',
        data
    })
}
// 根据id查询优惠

export async function getByIdCoupon(param) {
    return request({
        url: `/appVersion/getById?couponId=${param}`,
        method: 'get'
    })
}

// 根据id查询优惠券记录
export async function getCouponRecordById(param) {
    return request({
        url: `/coupon_new/findCouponById?couponRecordId=${param}`,
        method: 'get'
    })
}