<template>
    <div class="home">
        <div v-if="[-1, 1, 0, 2, 5, 6, 7, 8].includes(status)">
            <div class="top">
                <div class="top-title">OKprésta</div>
                <div class="top-row">
                    <div class="top-row-item"><img class="top-row-item-icon"
                            src="../../assets/home/icon_a1.png"><span>Umbral bajo</span></div>
                    <div class="top-row-item"><img class="top-row-item-icon"
                            src="../../assets/home/icon_a2.png"><span>Más simple</span></div>
                    <div class="top-row-item"><img class="top-row-item-icon"
                            src="../../assets/home/icon_a3.png"><span>Más rápido</span></div>
                </div>
            </div>
            <div class="product">
                <div class="product-title">
                    <img class="product-title-icon" src="../../assets/home/icon_amount_p.png">
                    <span>El monto disponible de su préstamo es</span>
                </div>
                <img class="product-mask" src="../../assets/home/img_money.png">
                <div class="product-number">{{ $thousands(applicationInfo.loanAmount || money[0]) }}</div>
                <!--未申请 -->
                <div v-if="[-1, 1, 0, 8].includes(status)" class="product-btn" @click="toLoan">
                    <span>Aplicar inmediatamente</span>
                    <svg-icon iconClass="go" class="product-btn-icon" />
                </div>
                <!-- 审核中 -->
                <div v-if="status == 2" class="product-result green_bg green">
                    <div class="product-result-title">Bajo revisión</div>
                    <div>Hola, su solicitud de préstamo está en revisión, espere pacientemente.</div>
                </div>
                <!-- 放款中 -->
                <div v-if="status == 6" class="product-result green_bg green">
                    <div class="product-result-title">Está prestando</div>
                    <div>Su solicitud de préstamo ha sido aprobada y el préstamo se está emitiendo.</div>
                </div>
                <!-- 放款失败 -->
                <div v-if="status == 7" class="product-result yellow_bg yellow">
                    <div class="product-result-title">Pago fallido</div>
                    <div>Su préstamo ha fallado, comuníquese con el servicio al cliente lo antes posible (
                        {{ customerServicePhone }})
                    </div>
                </div>
                <!-- 已拒绝 -->
                <div v-if="[5].includes(status)" class="product-result yellow_bg yellow">
                    <div class="product-result-title">Rechazado</div>
                    <div>Lo sentimos, aún no ha cumplido con nuestras condiciones de préstamo, intente nuevamente el {{
                    $dayjs(applicationInfo.refuseExpireTime).format("DD-MM-YYYY") }}</div>
                </div>
                <div v-if="[-1, 1, 0, 8].includes(status)" class="product-tip">
                    <span>Tasa de interés diaria 0,05%</span>
                    <span class="product-tip-split"></span>
                    <span>Préstamo rápido</span>
                </div>
            </div>
            <!-- <div class="process">
                <div class="process-title">Solo 3 pasos para completar</div>
                <div class="process-row">
                    <div class="process-row-item">
                        <img class="process-row-item-icon" src="../../assets/home/icon_register.png">
                        <div>Registrar</div>
                    </div>
                    <div class="process-row-item">
                        <img class="process-row-item-icon" src="../../assets/home/icon_audit.png">
                        <div>Auditoría</div>
                    </div>
                    <div class="process-row-item">
                        <img class="process-row-item-icon" src="../../assets/home/icon_pagos.png">
                        <div>Pagos</div>
                    </div>
                </div>
            </div> -->
            <!-- <img class="feedback" src="../../assets/home/img_help.png"> -->
            <div class="footer">- Pedir prestado es más fácil -</div>
        </div>
        <Passed v-if="[3, 4, 9].includes(status)" v-model:status="status" v-model:applicationInfo="applicationInfo">
        </Passed>
    </div>
</template>

<script>
import {computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Passed from './passed.vue';
import { latest } from '@/api/order';
import { canApply, analyzeLoan } from '@/api/loan'
import { getOrderStatus } from "@/utils/tools.js";

export default {
    components: { Passed },
    setup() {
        const store = useStore()
        const router = useRouter()
        const status = ref(-1); // 0 已完成 1逾期完成， 2 申请中， 3 还款中， 4 逾期中， 5 拒绝,6 放款中 7 放款失败 8 拒绝到期
        const applicationInfo = ref({})
        const customerServicePhone = computed(() => store.state.dict.customerServicePhone)

        const getLatest = async () => {
            const res = await latest()
            if (res) {
                applicationInfo.value = res
                status.value = res ? getOrderStatus(res.status) : -1
                if ([3,4].includes(status.value) && res.orderInfo && res.orderInfo.overdueDays == 0) {
                    status.value = 9 // 还款日
                }
            } else {
                status.value = -1
            }

        }

        const product = ref([]);
        let money = ref([])

        const getProduct = async () => {
            const res = await analyzeLoan()
            product.value = res
            handleProduct()
        }
        const handleProduct = () => {
            product.value.forEach(i => {
                if (!money.value.includes(i.amount)) {
                    money.value.push(i.amount)
                }
                money.value.sort()
            });
        }
        getLatest()
        getProduct()

        const toLoan = async () => {
            const res = await canApply()
            console.log(res)
            if (res) {
                router.push('/product')
            }
        }
        return {
            toLoan, status, applicationInfo, money,customerServicePhone
        }
    }
}
</script>

<style lang="less" scoped>
.home {
    height: 100vh;
    overflow-y: scroll;
    background-color: @Main-bg;

    .top {
        height: 229px;
        padding: 36.5px 14px 0;
        box-sizing: border-box;


        &-title {
            color: #1F254D;
            font-weight: bold;
            font-size: 24px;
            font-style: italic;
        }

        &-row {
            margin: 10px 0 10px;
            display: flex;
            font-size: 13px;
            color: #81939B;

            &-item {
                margin: 0 3px 0 0;

                &:last-child {
                    margin: 0;
                }

                &-icon {
                    margin: 0 5.5px 0 0;
                    width: 16px;
                    display: inline-block;
                    vertical-align: text-top;
                }
            }

        }
    }

    .product {
        margin: -112.5px 14px 0 16px;
        padding: 33.5px 0 24.5px;
        font-weight: bold;
        background: url('../../assets/home/bg_apply_p.png')no-repeat;
        background-size: 100% 100%;

        &-title {
            text-align: center;
            font-size: 14px;
            color: rgba(255, 255, 255, .7);

            &-icon {
                margin: 0 6.5px 0 0;
                width: 20px;
                height: 20px;
                display: inline-block;
                vertical-align: text-bottom;

            }
        }

        &-mask {
            margin: 13.5px 0 0 -6px;
            width: 338px;
            height: 150px;

        }

        &-number {
            margin: -160px 0 0;
            text-align: center;
            font-size: 55px;
            color: #fff;
        }

        &-btn {
            margin: 20px auto 0;
            width: 300px;
            height: 50px;
            line-height: 50px;
            font-size: 19px;
            color: @MainColor;

            text-align: center;

            &-icon {
                margin: 0 0 0 8.5px;
                width: 10px;
                height: 10px;
                vertical-align: middle;
                color: @MainColor;
            }

            border-radius: 27.5px;
            background: linear-gradient(to top, @Main-bg, #FFFFFF);
        }

        &-result {
            margin: 10px 15px 0;
            padding: 26px 19px 30px;
            font-size: 14px;
            text-align: center;
            border-radius: 0 27.5px 27.5px 27.5px;

            &-title {
                margin: 0 0 14px;
                font-size: 27px;
            }


        }

        &-tip {
            margin: 9px 0 0;
            text-align: center;
            font-size: 13px;
            color: rgba(255, 255, 255, .7);

            &-split {
                margin: 0 10px;
                width: 1px;
                height: 11px;
                background-color: rgba(255, 255, 255, .7);
            }
        }

        .green_bg {
            background: linear-gradient(to top, @Main-bg, #FFFFFF);

        }

        .yellow_bg {
            background: linear-gradient(to top, #FEFFCF, #FFFFFF);

        }

        .green {
            color: @MainColor;
        }

        .yellow {
            color: #F3841E
        }
    }

    .process {
        margin: 15.5px 15px 0;
        padding: 24px 15px 30px;
        font-weight: bold;
        background: url('../../assets/home/bg_step.png') no-repeat;
        background-size: 100% 100%;

        &-title {
            margin: 0 0 24px;
            color: #212750;
            font-size: 18px;
        }

        &-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: bold;

            &-item {
                font-size: 16px;
                color: #3F3D5A;
                text-align: center;

                &-icon {
                    margin: 0 auto 10px;
                    width: 49px;
                    height: 49px;
                }
            }
        }
    }

    .feedback {
        margin: 15.5px 15px 0;
        width: 345px;
    }

    .footer {
        margin: 15px 0 0;
        text-align: center;
        color: #B4C3BD;
        font-size: 13px;
    }
}
</style>