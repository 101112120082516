import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { Toast } from 'vant'
import appConfig from '@/config/app_config'
import md5 from 'md5'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
/**
 * @description 处理code异常
 * @param {*} code
 * @param {*} msg
 */

/**
 * @description axios初始化
 */
// const baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASEURL : 'https://app.okpresta-online.com'
const baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASEURL : '/'
// const baseURL = 'https://app.okpresta-online.com'

console.log(baseURL, 'baseURL')

const instance = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json,*/*',
    platform: appConfig.platform,
    google_referrer: '', //Google参数
    loginPlatform: 'APP', //登录平台 APP 和 H5
    language: 'en-US', //语言，中文:zh-CN 英文：en-US 塔加拉族语：tl-PH
    appId: appConfig.appId, //区分马甲包 6 hcash
    androidId: appConfig.packageName, //包名
    version: appConfig.appVersion, //app版本
    channel: appConfig.appChannel, // 渠道标识
    deviceId: '',
    'web-h5': 1 // 网页登陆
  }
})

/**
 
 * @description axios请求拦截器
 */
instance.interceptors.request.use(
  async(config) => {
    const fp = await FingerprintJS.load()
    const device = await fp.get()
    const memberId = sessionStorage.getItem('memberId')
    config.headers.deviceId = md5(device.visitorId + memberId)

    config.headers.x_x_path = config.url
    let token = sessionStorage.getItem('token')
    if (token) {
      config.headers.token = token
    }
    if (config.params?.rlatedType || config.data?.rlatedType) {
      config.headers['fallPage'] = config.params.rlatedType || config.data.rlatedType
      config.params && delete config.params.rlatedType
      config.data && delete config.data.rlatedType
    }
    store.commit('setLoading', true)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
  (response) => {
    const { data, config } = response
    const { code, msg, result } = data

    store.commit('setLoading', false)
    if (code == 401) {
      //身份过期
      Toast('Error de inicio de sesión, vuelva a iniciar sesión')
      router.replace('/Login')
    } else if (code == 408) {
      return data
    } else if (code !== 200) {
      Toast(msg)
      return Promise.reject(
        'data-center请求异常拦截:' +
          JSON.stringify({
            url: config.url,
            code,
            msg
          }) || 'Error'
      )
    } else {
      return result
    }
  },
  (error) => {
    const { message } = error
    store.commit('setLoading', false)
    if (error.response && error.response.data) {
      return Promise.reject(error)
    } else {
      Toast(message)
      return Promise.reject(error)
    }
  }
)

export default instance
