<template>
  <van-popup
    v-model:show="isShow"
    safe-area-inset-bottom
    position="bottom"
    @close="$emit('update:visible', false)"
  >
    <div class="guidePopup">
      <div class="guidePopup-title">
        <img
          class="guidePopup-title-icon"
          src="../../../assets//icons/icon_repay_guide@3x.png"
        />
        <span>{{ guideInfo.title }}</span>
        <img
          class="guidePopup-title-close"
          @click="$emit('update:visible', false)"
          src="../../../assets/icons/icon_return@3x.png"
        />
      </div>
      <div class="guidePopup-ul">
        <div
          class="guidePopup-ul-li"
          v-for="(item, index) of guideInfo.guides"
          :key="index"
        >
          {{ index + 1 }}. {{ item }}
        </div>
      </div>
    </div>
  </van-popup>
</template>
<script>
import { ref, watch } from "vue";
import { Popup } from "vant";

export default {
  props: {
    visible: { type: Boolean, default: false },
    guideType: { type: String, default: 'CLABE' },
  },
  emits: ["update:visible"],
  components: { [Popup.name]: Popup },
  setup(props) {
    const isShow = ref(false);
    watch(
      () => props.visible,
      (val) => {
        isShow.value = val;
      }
    );
    const guideList = {
      CLABE: {
        title: "Guía de pago",
        guides: [
          " Abra su aplicación de banco ",
          ' Seleccione "Transferencia Rápida"',
          " Ingrese los 18-digitos de su cuenta CLABE ",
          ' Ingrese "ALIBEY" en "Nombre de Beneficiario" ',
          ' Ingrese "Pago por préstamo',
          ' Ingrese la Deuda a pagar en la casilla de "Monto"',
          ' Haga Click en "Continuar"',
          ' Seleccione "Transferir" después de confirmar los datos sean correctos',
          " Ingrese su token o contraseña para realizar el pago y completar la transferencia",
        ],
      },
      Otro: {
        title: "Guía por vía",
        guides: [
          " Vaya a la tienda de conveniencia más cercana",
          " Encuentre al cajero y solicite realizar un pago por PAYNET",
          " Proporcione el código de barras del pago o el número de pago, por favor confirme que los datos sean correctos",
          " Paque el monto correspondiente en la caja cuando se haya ingresado los datos",
          " Pague en efectivo y guarde el comprobante;",
        ],
      },
      OXXO: {
        title: "Guía por via OXxo",
        guides: [
          " Vaya al OXXO más cercano",
          " Encuentre el cajero",
          " Solicite al cajero para realizar un pago porOXXO PAY; ''. Proporcione el Código QR o el número de pago de la figura, por favor confirme que los dato sean correctos",
          " Paque el monto correspondiente en la caja cuando se haya ingresado los datos",
          " Pague en efectivo y guarde el comprobant",
        ],
      },
    };
    const guideInfo = ref();
    // guideInfo.value = props.guideType ? guideList[props.guideType] :  guideList['CLABE']
    guideInfo.value =guideList[props.guideType]

    return {
      isShow,
      guideInfo,
    };
  },
};
</script>
<style lang="less" scoped>
.guidePopup {
  padding: 24px 18px;
  font-weight: bold;
  background-color: #fff;
  &-title {
    display: flex;
    align-items: center;
    color: #100c33;
    font-size: 21px;
    &-icon {
      margin: 0 9.5px 0 0;
      width: 25px;
      height: 25px;
    }
    &-close {
      margin-left: auto;
      width: 21px;
      height: 21px;
    }
  }
  &-ul {
    margin: 20px 0 0;
    color: #888f99;
    font-size: 14px;
    &-li {
      line-height: 1.5;
    }
  }
}
</style>
