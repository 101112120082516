<template>
  <div class="logout">
    <NavBack title="Cancelar cuenta"></NavBack>
    <div class="logout-top">
      <img
        class="logout-top-icon"
        src="../../assets/icons/icon_tips@3x.png"
        alt=""
      />
      Después de eliminar su cuenta, perderá los siguientes beneficios
    </div>
    <div class="logout-list">
      <div class="logout-list-item">
        <img
          class="logout-list-item-icon"
          src="../../assets/icons/icon_benefit1@3x.png"
          alt=""
        />
        <div>Mayor cantidad</div>
      </div>
      <div class="logout-list-item">
        <img
          class="logout-list-item-icon"
          src="../../assets/icons/icon_benefit2@3x.png"
          alt=""
        />
        <div>Mejor credito</div>
      </div>
      <div class="logout-list-item">
        <img
          class="logout-list-item-icon"
          src="../../assets/icons/icon_benefit3@3x.png"
          alt=""
        />
        <div>Bajo interés</div>
      </div>
      <div class="logout-list-item">
        <img
          class="logout-list-item-icon"
          src="../../assets/icons/icon_benefit4@3x.png"
          alt=""
        />
        <div>Revisa más rápido</div>
      </div>
    </div>
    <div class="link" @click="$router.push('logoutTips')">Recordatorio importante</div>
    <div class="btn" @click="logoutPopupVisible = true">
      Confirmar cierre de sesión
    </div>
  </div>
  <LogoutPopup
    v-model:visible="logoutPopupVisible"
    @change="logoutRes"
  ></LogoutPopup>
  <LogoutErrPopup v-model:visible="logoutErrPopupVisible"></LogoutErrPopup>
  <LogoutResPopup v-model:visible="logoutResPopupVisible"></LogoutResPopup>
</template>
<script>
import { ref } from "vue";
import LogoutPopup from "./components/logoutPopup.vue";
import LogoutErrPopup from "./components/logoutErrPopup.vue";
import LogoutResPopup from "./components/logoutResPopup.vue";

export default {
  components: { LogoutPopup, LogoutErrPopup, LogoutResPopup },
  setup() {
    const logoutPopupVisible = ref(false);
    const logoutErrPopupVisible = ref(false);
    const logoutResPopupVisible = ref(false);
    function logoutRes(res) {
      logoutPopupVisible.value = false
      if (res == "logoutErr") {
        logoutErrPopupVisible.value = true;
      } else {
        logoutResPopupVisible.value = true;
      }
    }
    return {
      logoutPopupVisible,
      logoutErrPopupVisible,
      logoutResPopupVisible,
      logoutRes,
    };
  },
};
</script>
<style lang="less" scoped>
.logout {
  height: 100vh;
  font-weight: bold;
  background-color: #f2f4f7;
  &-top {
    padding: 20px;
    display: flex;
    font-size: 16px;
    color: #fff;
    word-break: break-all;
    background: linear-gradient(to right, #e24040, #ef6767);
    &-icon {
      margin: 0 10px 0 0;
      width: 18px;
      height: 18px;
    }
  }
  &-list {
    background-size: 100% 100%;
    margin: 15px;
    display: flex;
    flex-wrap: wrap;
    &-item {
      margin: 0 15px 15px 0;
      padding: 25px 0 20px;
      width: 165px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #1f254d;
      font-size: 16px;
      text-align: center;
      border-radius: 15px;
      background-color: #fff;
      &:nth-child(2n) {
        margin: 0 0 15px 0;
      }
      &-icon {
        margin: 0 0 15px 0;
        width: 70px;
        height: 70px;
        display: inline-block;
      }
      &-hint {
        margin: 4px 0 0;
        font-weight: normal;
        color: #81939b;
        font-size: 14px;
      }
    }
  }
    .link {
    position: fixed;
    bottom: 80px;
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: @MainColor;
    text-decoration: underline;
  }
  .btn {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    height: 55px;
    line-height: 55px;
    font-size: 19px;
    color: #fff;
    text-align: center;
    background: linear-gradient(270deg, #33a68c 0%, #0e8e6b 100%);
    border-radius: 27.5px;
  }
}
</style>