import SvgIcon from "@/components/SvgIcon.vue";

//自动加载svg目录下所有图标
//动态导入 （传递指定目录 ，不要递归，利用什么规则打包）
const req = require.context("./svg", false, /\.svg$/); //返回新函数
//遍历所有图标名称， keys返回名称的数组
//相当于动态require
req.keys().map(req);

export function install (app){
    app.component("svg-icon", SvgIcon);

}