<template>
  <div style="overflow: hidden">
    <NavBack title="third" url="/RepaymentMethod"></NavBack>
    <div class="h-line-E6E8EB"></div>
    <iframe
      style="position: fixed; width: 100%; height: 100%; top: 55px"
      :src="url"
      frameborder="0"
      scrolling="yes"
      marginheight="55px"
      id="bdIframe"
    >
    </iframe>
  </div>
</template>

<script>
import NavBack from "@/components/NavBack";
import { onMounted, ref } from "vue";
import {useRoute} from 'vue-router'
export default {
  name: "third_page",
  components: { NavBack },
  setup() {
    const url = ref();
    const route = useRoute()
    onMounted(() => {
      /**
       * iframe-宽高自适应显示
       */
      const oIframe = document.getElementById("bdIframe");
      const deviceWidth = document.documentElement.clientWidth;
      const deviceHeight = document.documentElement.clientHeight;
      oIframe.style.width = deviceWidth + "px";
      oIframe.style.height = deviceHeight + "px";
      url.value = route.query.url;
    });
    return {
      url,
    };
  },
};
</script>

<style scoped>

.h-line-E6E8EB {
    height: 1px;
    background: #E6E8EB;
}

</style>