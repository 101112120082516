<template>
  <div class="login">
    <div class="login-content">
      <div class="login-content-title">
        <div>¡Hola!</div>
        <div>Bienvenido a OKprésta</div>
        <div class="login-content-title-hint">Anicie sesión con su número de teléfono.</div>
      </div>
      <div class="login-content-phone">
        <label for="phone">
          <div class="login-content-phone-input">
            <span class="login-content-phone-input-label">+52</span>
            <input class="login-content-phone-input-number" type="text" id="phone" maxlength="10" v-model="phone" placeholder="Número de teléfono" />
          </div>
        </label>
      </div>

      <div class="login-content-btns">
        <van-button class="primary-btn" :disabled="phone.length < 10" block @click="getCodeCheck()">Código de verificación </van-button>
      </div>
      <div class="login-content-hint">
        <img class="login-content-hint-check" @click="isAgree = !isAgree" :src="isAgree ? require('../../assets/icons/select_agreement@3x.png') : require('../../assets/icons/n_select_agreement@3x.png')" />
        <div>
          He leído y estoy de acuerdo con
          <a class="login-content-hint-link" href="https://product.okpresta-online.com/agreement/agreement.html">‘Acuerdo de prestamo’</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useRouter, useRoute } from 'vue-router'
import { getCurrentInstance } from 'vue'
import { trimAll } from '@/utils/tools.js'
import { getQuery } from '@/utils/tools'
import { downApp } from '@/utils/app'
import { verifyPhoneRegister, associateCallSale } from '@/api/app'
import { watch } from 'vue'

export default {
  name: 'UserLogin',
  setup() {
    const { appContext } = getCurrentInstance()
    const router = useRouter()
    const route = useRoute()
    const phone = ref('')
    const isAgree = ref(false)
    watch(
      () => route.query,
      (value) => {
        if (value.oldphone) {
          router.push({
            path: '/smsCode',
            query: {
              type: 1, // 短信1，语音2
              phone: value.oldphone
            }
          })
        }
      },
      { immediate: true }
    )
    const checkUser = async (phone) => {
      const res = await verifyPhoneRegister({ phone })
      return res
    }
    async function getCodeCheck() {
      if (phone.value.length < 10 || phone.value.length > 10) {
        appContext.config.globalProperties.$toast('por favor ingrese un número de teléfono móvil válido')
        return false
      }
      if (!isAgree.value) {
        appContext.config.globalProperties.$toast('Por favor lea y acepte el acuerdo primero')
        return false
      }
      // 绑定电销用户
      const operatorId = getQuery('operatorId')
      if (operatorId) {
        sessionStorage.setItem('operatorId', operatorId)
        const isOld = await checkUser(trimAll(phone.value))
        if (isOld) {
          associateCallSale({ operatorId, phone: trimAll(phone.value) })
          downApp(trimAll(phone.value))
          sessionStorage.removeItem('operatorId') //点击后清除
          return
        }
      }

      router.push({
        path: '/smsCode',
        query: {
          type: 1, // 短信1，语音2
          phone: trimAll(phone.value)
        }
      })
    }
    return {
      phone,
      isAgree,
      getCodeCheck
    }
  }
}
</script>
<style lang="less" scoped>
.login {
  min-height: 100vh;
  position: relative;
  overflow: visible;

  &-content {
    width: 100%;
    padding: 76px 28px 25px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: #fff;
    z-index: 9;

    &-title {
      color: #0b0d08;
      font-size: 27px;
      font-weight: bold;
      line-height: 35px;

      &-hint {
        margin-top: 10px;
        color: #47494a;
        opacity: 0.8;
        font-size: 16px;
      }
    }

    &-phone {
      margin: 54px 0 24px;
      padding: 21px 16px;
      font-weight: bold;
      background-color: #f2f4f7;
      border-radius: 8px;

      &-input {
        border: none;
        font-size: 18px;
        color: #100c33;

        &-label {
          color: #0b0d08;
        }

        &-number {
          padding-left: 12.5px;
          border: none;
          background-color: #f5f7fa;
        }
      }

      .opacity3 {
        opacity: 0.3;
      }
    }

    &-hint {
      position: absolute;
      bottom: 20px;
      display: flex;
      align-items: top;
      font-size: 14px;
      font-weight: bold;
      color: #47494a;

      &-check {
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }

      &-link {
        text-decoration: underline;
        color: @MainColor;
      }
    }

    &-btns {
      margin-top: 30px;
    }
  }

  .phone-input {
    position: absolute;
    z-index: -1;
    left: -9999px;
    top: -99999px;
  }
}
</style>
