<template>
  <div class="repayment">
    <div v-if="!loanInfo.orderInfo" class="repayment-empty">
      Orden de reembolso no recibida
    </div>
    <div v-else>
      <div class="repayment-top">
        <div class="repayment-top-no repayment-top-row">
          <div class="repayment-top-no-number" :class="{ 'repayment-top-no-number-red': isOverdue }">
            No.{{ loanInfo.orderInfo.applicationId }}
          </div>
          <div class="repayment-top-no-tag" :class="{ 'repayment-top-no-tag-red': isOverdue }">
            <span v-if="isOverdue">Atrasado</span>
            <span v-else>En repago</span>
          </div>
        </div>
        <div class="repayment-top-label repayment-top-row">
          <div>Importe total a pagar</div>
          <div>Fecha de caducidad</div>
        </div>
        <div class="repayment-top-value repayment-top-row">
          <div class="repayment-top-value-money">
            <div class="repayment-top-value-money-number">
              <!-- ${{ $thousands(loanInfo.sRepaymentAmount) }} -->
              ${{ $thousands(c_amount) }}
            </div>
          </div>
          <div>
            {{ $dayjs(loanInfo.orderInfo.sRepaymentTime).format("DD-MM-YYYY") }}
          </div>
        </div>
        <template v-if="isOverdue">
          <div class="repayment-top-row repayment-top-overdue">
            <div class="label">Dias atrasados</div>
            <div class="repayment-top-overdue-value">
              {{ loanInfo.orderInfo.overdueDays }}dias
            </div>
          </div>
          <div class="repayment-top-row repayment-top-overdue">
            <div class="label">Tarifas vencidas</div>
            <div class="repayment-top-overdue-value">
              ${{ loanInfo.orderInfo.overduePayment }}
            </div>
          </div>
        </template>
        <div class="repayment-top-desc" :class="{ 'repayment-top-desc-red': isOverdue }">
          Los préstamos vencidos incurrirán en intereses de penalización
          adicionales. Cuando nuestro sistema reciba su reembolso, se
          actualizará el estado de su préstamo
        </div>
        <div class="repayment-top-action" @click="
          $router.push({
            path: '/repaymentDetail',
            query: { applicationId: loanInfo.orderInfo.applicationId },
          })
        ">
          Ver detalles del préstamo
          <img class="repayment-top-action-icon" src="../../assets/icons/arrow_step_green@3x.png" />
        </div>
      </div>
      <PayMethod v-model:loanInfo="loanInfo" v-model:amount="c_amount"></PayMethod>
      <div class="delay" @click="toDelay">Reembolso prorrogado</div>
    </div>

    <GuidePopup v-model:showModal="showModal"></GuidePopup>
  </div>
</template>

<script>
import { onMounted, ref, watch,computed } from "vue";
import GuidePopup from "../repayment/components/GuidePopup";
import PayMethod from "./components/paymethod";
import { Popover } from "vant";
import { latest ,queryDelayAmount} from "@/api/order.js";
import { useRouter, useRoute } from "vue-router";
import { getOrderStatus } from '@/utils/tools.js'

export default {
  name: "Repayment",
  components: { GuidePopup, [Popover.name]: Popover, PayMethod },
  setup() {
    const route = useRoute()
    const router = useRouter();
    const showModal = ref(false);
    const isBank = ref(true);
    const loanInfo = ref({});
    const isOverdue = ref(false);
    watch(loanInfo, () => {
      const bol = Boolean(loanInfo.value.orderInfo?.overdueDays > 0);
      isOverdue.value = bol;
    });
    const delayInfo = ref({})

    const c_amount = computed(() => {
      if (route.query.type == 2) {
        return  delayInfo?.value.amount || 0
      } else {
        return loanInfo.value.sRepaymentAmount
      }
    })
    onMounted(async () => {
      await getData();
    });

    async function getData() {
      const res = await latest();
      sessionStorage.setItem("applicationId", res.id);
      if (res) {
        let status = getOrderStatus(res.status)
        if (![3, 4].includes(status)) {
          // 结清跳转
          router.push('/')
        }
        if (res?.orderInfo) {
          loanInfo.value = res;
        }
      } else {
        router.push('/')
      }

    }
    const toDelay = async () => {
      const res = await queryDelayAmount({ orderId: loanInfo.value.orderInfo.id })
      if (res) { delayInfo.value = res }

      router.push({
        path: '/repayment',
        query: {
          type: 2
        }
      })
    }

    return {
      c_amount,
      toDelay,
      isOverdue,
      showModal,
      isBank,
      loanInfo,
    };
  },
};
</script>

<style lang='less' scoped>
.repayment {
  background-color: #f2f4f7;
  height: 100vh;
  overflow-y: scroll;

  &-empty {
    padding: 100px 0;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    color: #bfbfbf;
  }

  &-explain {
    padding: 20px 12px 16px;
    color: #100c33;
    font-size: 18px;
    font-weight: bold;
    border-radius: 8px;

    &-hint {
      margin: 6px 0 0;
      color: #888f99;
      font-size: 12px;
    }
  }

  &-top {
    padding: 15px;
    font-weight: bold;
    background-color: #fff;

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-no {
      &-number {
        font-size: 21px;
        color: @MainColor;

        &-red {
          color: #e34443;
        }
      }

      &-tag {
        padding: 5px 12px;
        line-height: 1;
        font-size: 14px;
        color: #fff;
        border-radius: 12.5px;
        .background();

        &-red {
          background: linear-gradient(to right, #ee4747, #f78b8b);
        }
      }
    }

    &-label {
      margin: 24px 0 0;
      font-size: 15px;
      color: #888f99;
    }

    &-value {
      margin: 6px 0 0;
      font-size: 21px;
      color: #130e3d;

      &-money {
        &-number {
          display: inline-block;
          max-width: 160px;
          overflow-x: scroll;
        }

        font-size: 35px;

        &-icon {
          margin: 0 0 0 10px;
          width: 22px;
          height: 22px;
        }
      }
    }

    &-overdue {
      margin: 10px 0 0;
      font-size: 14px;
      color: #888f99;

      &-value {
        font-size: 18px;
        color: #e34443;
      }
    }

    &-desc {
      margin: 15.5px 0 0;
      padding: 14.5px 13px;
      color: #F19149;
      font-size: 14px;
      border-radius: 15px;
      word-break: break-all;
      background-color: #FBF8EE;

      &-red {
        color: #e34443;
        background-color: #fbeeee;
      }
    }

    &-action {
      margin: 20px 0 0;
      color: @MainColor;
      font-size: 16px;
      text-align: center;

      &-icon {
        display: inline-block;
        margin-left: 8.5px;
        width: 11px;
        height: 11px;
        vertical-align: middle;
      }
    }
  }

  &-panel {
    margin: 20px 15px;

    &-title {
      position: relative;
      color: #888f99;
      font-size: 16px;
      font-weight: bold;
      text-indent: 12px;

      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -3.5px;
        content: "";
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: @MainColor;
      }
    }

    &-pay {
      margin: 15px 0 0 0;
      padding: 15px;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      color: #130e3d;
      border-radius: 15px;
      background-color: #fff;

      &-avtive {
        color: #fff;
        background-color: #14533f;
      }

      &-icon {
        margin: 0 13px 0 0;
        width: 40px;
        height: 40px;
      }
    }

    &-payDetail {
      position: relative;
      margin: 14.5px 0 0;
      padding: 0 15px;
      border-radius: 15px;
      background-color: #fff;

      &-arrow {
        position: absolute;
        top: -9.5px;
        left: 47px;
        width: 21px;
        height: 9.5px;
      }

      &-item {
        padding: 12px 0 20px;
        border-bottom: 1px solid #f2f4f7;

        &:last-child {
          border: none;
        }

        &-row {
          display: flex;
          align-items: center;
          color: #130e3d;
          font-size: 21px;
          font-weight: bold;

          &-icon {
            margin: 0 14.5px 0 0;
            width: 34px;
            height: 34px;
          }

          &-arrow {
            margin: 0 0 0 auto;
            width: 11px;
            height: 11px;
          }

          &-eidt {
            margin: 0 0 0 auto;
            width: 20px;
            height: 20px;
          }

          &-copy {
            margin: 0 0 0 auto;
            padding: 5px 10.5px;
            font-size: 14px;
            font-weight: bold;
            color: #fff;
            border-radius: 12.5px;
            background: linear-gradient(to bottom, @MainColor, @SecondaryColor);
          }
        }

        &-code {
          margin: 17.5px 0 0 0;
          width: 100%;
          height: 81px;
        }

        &-desc {
          text-indent: 49.5px;
          font-size: 13px;
          color: #888f99;

          &-hint {
            font-size: 11px;
          }
        }
      }
    }
  }
  .delay {
    padding: 0 0 17px;
    font-size: 16px;
    color: @MainColor;
    text-align: center;
    text-decoration: underline;
  }
}
</style>