<template>
  <NavBack url="/login"></NavBack>
  <div class="sms-code">
    <div class="sms-code-title">
      <div class="font-bold">Ingrese el código OTP</div>
      <div class="sms-code-title-hint">Ingrese SMS OTP para verificar su número de teléfono.</div>
    </div>
    <SmsCodeInput v-model:code="smsCode" :number="6"></SmsCodeInput>

    <div class="sms-code-options">
      <div class="sms-code-options-msg">
        <div>SMS corto OTP no recibido,</div>
        <van-count-down ref="countDown" v-show="isCountDown" :time="time" @finish=";(isCountDown = false), countDown.reset()">
          <template #default="timeData">
            <span class="sms-code-options-msg-count-down">{{ timeData.seconds }}s reenviar</span>
          </template>
        </van-count-down>
      </div>
      <div class="sms-code-options-list" v-show="!isCountDown">
        <div class="sms-code-options-list-item" @click="getCode(1)">OTP de voz</div>
        <span class="sms-code-options-list-divider">|</span>
        <div class="sms-code-options-list-item" @click="getCode(2)">Reenviar OTP</div>
      </div>
    </div>

    <div class="sms-code-btns">
      <van-button class="primary-btn" :disabled="smsCode.length < 6" block @click="toLogin">Verificar</van-button>
    </div>
  </div>
</template>
<script>
import NavBack from '@/components/NavBack'
import SmsCodeInput from '@/components/SmsCodeInput'
import { ref, onMounted, watch, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { CountDown } from 'vant'
import { requestVerifyCode, login } from '@/api/user.js'
import { associateCallSale } from '@/api/app'
import { downApp } from '@/utils/app'
import { useStore } from 'vuex'
export default {
  name: 'SmsCode',
  components: { [CountDown.name]: CountDown, NavBack, SmsCodeInput },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const smsCode = ref('')
    const time = ref(60 * 1000)
    const countDown = ref(null)
    const isCountDown = ref(true)
    const newUser = ref(false)
    const newUserOperatorId = ref(0)
    const phone = route.query.phone
    const redirect = computed(() => store.state.redirect)

    onMounted(() => {
      getCode(1)
    })
    watch(
      () => smsCode.value,
      (val) => {
        if (val.length == 6) {
          toLogin()
        }
      }
    )
    async function getCode(type) {
      countDown.value.reset()
      let params = {
        phone,
        type
      }
      const operatorId = sessionStorage.getItem('operatorId')
      if (operatorId) {
        params.rlatedType = 1 // 不是老用户 验证码接口获取添加标识 不然不能请求成功
        newUser.value = true
        newUserOperatorId.value = operatorId
      } else {
        newUser.value = false
      }
      isCountDown.value = true
      await requestVerifyCode(params)
      countDown.value.start()
    }
    async function toLogin() {
      const params = {
        phone,
        type: route.query.type,
        verifyCode: smsCode.value
      }
      const res = await login(params)
      // 绑定电销用户

      if (newUser.value) {
        await associateCallSale({ operatorId: newUserOperatorId.value, phone })
        downApp(phone)
        sessionStorage.removeItem('operatorId') //点击后清除
        return
      }
      sessionStorage.setItem('token', res.token)
      sessionStorage.setItem('memberId', res.memberId)
      sessionStorage.setItem('phone', res.phone)
      if (redirect.value) {
        router.replace(redirect.value)
      } else {
        router.replace('/repayment')
      }
    }
    return {
      smsCode,
      countDown,
      isCountDown,
      time,
      getCode,
      toLogin,
      phone,
      newUser,
      newUserOperatorId
    }
  }
}
</script>
<style lang="less" scoped>
.sms-code {
  padding: 0 28px 30px;
  &-title {
    margin: 55px 0 50px;
    font-size: 27px;
    font-weight: bold;
    color: #0b0d08;
    &-hint {
      margin: 30px 0 0;
      font-size: 16px;
      line-height: 1.5;
      color: #14171b;
      opacity: 0.9;
    }
  }
  &-options {
    margin: 30px 0 0;
    font-size: 14px;
    color: #47494a;
    font-weight: bold;
    text-align: center;
    &-msg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &-link {
        display: flex;
        color: @MainColor;
      }
      &-count-down {
        margin: 12px 0 0;
        padding: 12px 13px;
        display: inline-block;
        color: @MainColor;
        line-height: 1;
        border-radius: 18px;
        background-color: #f2f4f7;
      }
    }
    &-list {
      margin: 18px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      &-divider {
        margin: 0 30px;
        display: inline-block;

        color: #ebedf0;
      }
      &-item {
        font-size: 16px;
        color: @MainColor;
      }
    }
    &-voice {
      margin: 15px 0 0;
      text-decoration: underline;
      color: @MainColor;
    }
  }
  &-btns {
    margin: 74px 0 0;
  }
}
</style>
