<template>
  <div v-if="showBack" class="nav-back">
    <img @click="nav" v-show="showIcon" class="nav-back-icon"
      :src="require('../assets/icons/back_arrow_black@3x.png')" />
    <span class="font-bold nav-back-text ">{{ title }}</span>
  </div>
  <div v-else></div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref } from 'vue'
export default {
  name: "nav_back",
  props: {
    title: { default: "", type: String },
    showIcon: { default: true, type: Boolean },
    url: { default: "", type: String },
  },
  setup(props) {
    const router = useRouter();
    const showBack = ref(true);
    let userAgent = navigator.userAgent.toUpperCase(); //获取UA信息
    console.log(userAgent)
    if (userAgent.indexOf("APP_INNER") != -1) {
      //判断ua中是否含有和app端约定好的标识ezhouxing
      showBack.value = false;
    }
    function nav() {
      if (props.url) {
        router.push({ path: props.url });
      } else {
        router.go(-1);
      }
    }
    return {
      nav,
      showBack,
    };
  },
};
</script>

<style lang="less" scoped>
.nav-back {
  position: sticky;
  top: 0;
  padding: 32px 14px 16px;
  font-size: 16px;
  color: #0b0d08;
  background-color: #fff;
  z-index: 9;
  display: flex;

  &-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
  }

  &-text {
    flex: 1;
    text-align: center;
    text-decoration: -20px;
  }
}
</style>
