<template>
  <div class="bank">
    <NavBack title="Kartu bank"></NavBack>
    <div class="bank-list">
      <div class="bank-list-item">
        <div class="bank-list-item-top">
          <img
            class="bank-list-item-top-icon"
            src="../../assets/icons/icon_bank@3x.png"
          />
          <div>{{ bankInfo?.bank || "--" }}</div>
        </div>
        <div class="bank-list-item-text">
          <div class="bank-list-item-text-label">
            Número de tarjeta bancaria
          </div>
          <div class="bank-list-item-text-number">
            **** **** **** {{ bankInfo.accountNo?.slice(-4) || "--" }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bank-action">
        <van-button block round class="default-btn">Ganti kartu bank</van-button>
    </div> -->
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";
import { onMounted } from "vue";
export default {
  name: "BankCard",
  setup() {
    const store = useStore();
    const bankInfo = ref({});
    onMounted(async () => {
      const res = await store.dispatch("getUserInfo");
      bankInfo.value = {
        accountNo: res.accountNo,
        bank: res.bank,
      };
    });
    return {
      bankInfo,
    };
  },
};
</script>

<style lang="less" scoped>
.bank {
  &-list {
    padding: 15px;
    &-item {
      padding: 17px 20px;
      height: 150px;
      font-weight: bold;
      color: #fff;
      background-image: url("../../assets/images/bg_bank@3x.png");
      background-size: 100% 100%;
      &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        &-icon {
          width: 37px;
          height: 37px;
        }
      }
      &-text {
        margin: 30px 0 0;
        &-label {
          font-size: 13px;
        }
        &-number {
          margin: 10px 0 0;
          font-size: 30px;
        }
      }
    }
  }
  &-action {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    .default-btn {
      color: @MainColor;
    }
  }
}
</style>