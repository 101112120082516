<template>
    <div class="passed">
        <div class="passed-nav">
            <em>OKprésta</em>
            <!-- <img class="passed-nav-img" src="../../assets/passed/icon_title.png"> -->
        </div>
        <div class="passed-top" :class="{ 'green-bg': status == 3, 'purple-bg': status == 9, 'red-bg': status == 4 }">
            <div class="passed-top-title">Por favor pague a tiempo, ayudará a aumentar el monto del préstamo.
            </div>
            <div class="passed-top-card">
                <div class="passed-top-card-box">
                    <div class="passed-top-card-row">
                        <span>Deberías pagar el préstamo</span>
                        <span class="passed-top-card-row-no">NO.{{ applicationInfo.id }}</span>
                    </div>
                    <div class="passed-top-card-money">${{ $thousands(applicationInfo.sRepaymentAmount) }}</div>
                    <div v-if="status == 4" class="overdue">
                        <div class="overdue-row">
                            <img class="overdue-row-icon" src="../../assets/passed/icon_tips_white.png">
                            <span>Cargo por demora</span>
                            <span class="overdue-row-num">${{ applicationInfo.orderInfo.overdueDelayTax }}</span>
                        </div>
                        <div class="overdue-row">
                            <img class="overdue-row-icon" src="../../assets/passed/icon_tips_white.png">
                            <span>Dias atrasados</span>
                            <span class="overdue-row-num">{{ applicationInfo.orderInfo.overdueDays }}días</span>
                        </div>
                    </div>
                    <div class="passed-top-card-spec">
                        <div class="passed-top-card-spec-item">
                            <div>Plazo del préstamo</div>
                            <div class="passed-top-card-spec-item-value">{{ applicationInfo.orderInfo.loanDays }}días
                            </div>
                        </div>
                        <div class="passed-top-card-spec-item">
                            <div>Fecha de vencimiento</div>
                            <div class="passed-top-card-spec-item-value">{{
                            $dayjs(applicationInfo.orderInfo.sRepaymentTime).format("DD-MM-YYYY")
                            }}
                            </div>
                        </div>
                    </div>
                    <div class="passed-top-card-detail" @click="toDetail(1)">
                        Ver detalles del préstamo
                        <svg-icon class="passed-top-card-detail-icon"
                            :class="{ 'green': status == 3, 'purple': status == 9, 'red': status == 4 }"
                            iconClass="arrow"></svg-icon>

                    </div>
                </div>
                <div class="passed-top-card-coupon"
                    :class="{ 'green': status == 3, 'purple': status == 9, 'red': status == 4 }">
                    <svg-icon class="passed-top-card-coupon-icon" iconClass="coupon"></svg-icon>
                    <span>Se pueden utilizar {{ couponCoun }} cupones de pago</span>
                    <div class="passed-top-card-coupon-before"
                        :class="{ 'green2': status == 3, 'purple2': status == 9, 'red2': status == 4 }"></div>
                    <div class="passed-top-card-coupon-after"
                        :class="{ 'green2': status == 3, 'purple2': status == 9, 'red2': status == 4 }"></div>
                </div>
            </div>
        </div>
        <div class="passed-center">
            <div class="passed-center-title">Días restantes hasta la fecha de vencimiento</div>
            <div class="passed-center-days">
                <div class="passed-center-days-num"
                    :class="{ 'primary-border': status == 3, 'purple-border': status === 9, 'red-border': status == 4 }"
                    v-for="(i, index) of overdueDays" :key="index">{{ i }}</div>
            </div>
            <van-button class="primary-btn" @click="toDetail(2)"
                :class="{ 'purple-btn': status == 9, 'red-btn': status == 4 }" block round>
                Pagar el préstamo</van-button>
            <div class="passed-center-tip">
                <img class="passed-center-tip-icon" src="../../assets/passed/icon_tips.png">
                <span>Los pagos atrasados incurrirán en intereses de mora adicionales.</span>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue';
import { canUseCouponNum } from "@/api/coupon.js";
import { useRouter } from "vue-router"
export default {
    props: {
        status: { type: Number, default: 3 }, // 0 已完成 1逾期完成， 2 申请中， 3 还款中， 4 逾期中， 5 拒绝,6 放款中 7 放款失败 8 拒绝到期
        applicationInfo: { type: Object, default: () => ({}) }

    },
    setup(props) {
        const router = useRouter()
        onMounted(async () => {
            await getCouponNum();
        });
        const couponCoun = ref()
        const getCouponNum = async () => {
            const res = await canUseCouponNum();
            couponCoun.value = res
        }
        const imgs = [{
            status: 3,
            cupon: require('../../assets/passed/coupon_green.png'),
            arrow: require('../../assets/passed/icon_green_arrow.png'),
            bg: require('../../assets/passed/coupon_frame1.png'),
        },
        {
            status: 9,
            cupon: require('../../assets/passed/coupon_purple.png'),
            arrow: require('../../assets/passed/icon_purple_arrow.png'),
            bg: require('../../assets/passed/coupon_frame2.png'),

        },
        {
            status: 4,
            cupon: require('../../assets/passed/coupon_red.png'),
            arrow: require('../../assets/passed/icon_red_arrow.png'),
            bg: require('../../assets/passed/coupon_frame3.png'),

        }]

        const img = computed(() => imgs.find(i => i.status == props.status))
        const overdueDays = computed(() => {
            let tem = props.applicationInfo.orderInfo.overdueDays
            tem = Math.abs(tem) + ''
            tem = tem.length == 1 ? '00' + tem : tem.length == 2 ? '0' + tem : tem
            return tem
        })
        const toDetail = (type) => {
            sessionStorage.removeItem('applicationId')
            sessionStorage.removeItem('repaymentCouponId')

            let url = type == 1 ? 'repaymentDetail' : 'repayment'
            router.push(url)

        }
        return { img, overdueDays, couponCoun, toDetail }
    }
}
</script>

<style lang="less" scoped>
.passed {
    font-weight: bold;
    overflow-y: scroll;

    &-nav {
        padding: 14px 0 10px;
        background-color: #fff;
        text-align: center;
        font-size: 22px;

        &-img {
            margin: 0 auto;
            width: 141.5px;
            height: 23px;
        }
    }

    .green-bg {
        background: linear-gradient(to top, @SecondaryColor, @MainColor)
    }

    .purple-bg {
        background: linear-gradient(to top, #9B56E8, #5831C5)
    }

    .red-bg {
        background: linear-gradient(to top, #F2B99A, #ED5656);
    }

    .green {
        color: @MainColor;
    }

    .purple {
        color: #854ADD;
    }

    .red {
        color: #E86856
    }

    .green2 {
        background: @SecondaryColor;
    }

    .purple2 {
        background: #9B56E8;
    }

    .red2 {
        background: #F2B99A;
    }


    &-top {
        padding: 14px 15px 20px;


        &-title {
            margin: 0 0 12.5px;
            color: rgba(255, 255, 255, .8);
            font-size: 13px;
        }

        &-card {
            border-radius: 20px;
            overflow: hidden;

            &-box {
                padding: 20px 15px;
                // background: url('../../assets/passed/pay_white1.png');
                // background-size: 100% 100%;
                background: #fff;
            }

            .overdue {
                padding: 10px 15px 16px;
                color: #fff;
                background: url('../../assets/passed/overdue_frame.png');
                background-size: 100% 100%;

                &-row {
                    margin: 10px 0 0;
                    display: flex;
                    align-items: center;
                    font-size: 14px;

                    &-num {
                        margin-left: auto;
                        font-size: 15px;
                    }

                    &-icon {
                        margin: 0 9px 0 0;
                        width: 14px;
                        height: 14px;
                    }
                }

            }

            &-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 15px;
                color: #81939B;

                &-no {
                    font-size: 14px;
                }
            }

            &-money {
                font-size: 50px;
                color: #212750;
            }

            &-spec {
                margin: 30px 0 0;

                &-item {
                    display: inline-block;
                    width: 50%;
                    color: #81939B;
                    font-size: 13px;

                    &-value {
                        margin: 5px 0 0;
                        color: #212750;
                        font-size: 19px;
                    }
                }
            }

            &-detail {
                margin: 25px 0 0;
                color: #81939B;
                font-size: 13px;

                &-icon {
                    margin: 0 0 0 3px;
                    width: 10px;
                    height: 10px;
                    font-size: 10px;
                }
            }

            &-coupon {
                position: relative;
                padding: 20px 15px 18px;
                font-size: 14px;
                background: #fff;
                border-top: 1px dashed;

                &-after,
                &-before {
                    position: absolute;
                    top: -10px;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    z-index: 2;
                    background-color: @SecondaryColor;
                }

                &-after {
                    right: -10px;
                }

                &-before {
                    left: -10px;
                }

                &-icon {
                    display: inline-block;
                    margin: 0 13px 0 0;
                    width: 22px;
                    height: 22px;
                    font-size: 20px;
                    vertical-align: middle;
                }
            }
        }
    }

    &-center {
        margin: 15px;
        padding: 34.5px 17.5px 28.5px;
        border-radius: 15px;
        background-color: #ffffff;

        &-title {
            color: #81939B;
            font-size: 14px;
        }

        &-days {
            margin: 25px 0 30px;
            display: flex;
            justify-content: center;

            &-num {
                margin: 0 15px 0 0;
                padding: 10px 20px;
                font-size: 50px;
                color: #212750;
                border-radius: 10px;

                &:last-child {
                    margin: 0;
                }
            }
        }

        &-tip {
            margin: 13.5px 0 0;
            color: #F0A056;

            &-icon {
                margin: 0 6.5px 0 0;
                width: 14px;
                height: 14px;
                display: inline-block;
                vertical-align: text-top;
            }
        }
    }

    .purple-btn {
        background: linear-gradient(to left, #9B56E8, #5831C5)
    }

    .red-btn {
        background: linear-gradient(to left, #F2B99A, #ED5656);
    }

    .primary-border {
        border: 1px @MainColor solid;
    }

    .purple-border {
        border: 1px #9B56E8 solid;
    }

    .red-border {
        border: 1px #ED5656 solid;
    }
}
</style>