<template>
  <div class="contactInfo">
    <NavBack title="Contacto"></NavBack>
    <div class="contactInfo-card">
      <div v-if="contactList.length == 0" class="contactInfo-card-empty">
        Sin contacto
      </div>
      <div
        v-else
        class="contactInfo-card-row"
        v-for="(item, index) of contactList"
        :key="item.id"
      >
        <div class="contactInfo-card-row-title">Contacto{{ index + 1 }}</div>
        <div class="contactInfo-card-row-box">
          <div
            class="contactInfo-card-row-value"
            :class="{ 'contactInfo-card-row-value-active': !isReview }"
          >
            <!-- <input
              type="text"
              class="contactInfo-card-row-value-input"
              placeholder="Relación"
              disabled
              v-model="item.relationship"
            /> -->
            <div  class="contactInfo-card-row-value-input">{{item.relationship}}</div>
            <img
              class="contactInfo-card-row-value-arrow"
              src="../../assets/icons/arrow_step1@3x.png"
            />
          </div>
        </div>
        <div class="contactInfo-card-row-box">
          <div
            class="contactInfo-card-row-value"
            :class="{ 'contactInfo-card-row-value-active': !isReview }"
          >
            <!-- <input
              type="text"
              class="contactInfo-card-row-value-input"
              placeholder="Nombre"
              disabled
              v-model="item.name"
            /> -->
            <div class="contactInfo-card-row-value-input">
              {{ item?.name || "--" }}
            </div>
            <img
              class="contactInfo-card-row-value-arrow"
              src="../../assets/icons/arrow_step1@3x.png"
            />
          </div>
          <div
            class="contactInfo-card-row-value"
            :class="{ 'contactInfo-card-row-value-active': !isReview }"
          >
            <input
              type="text"
              class="contactInfo-card-row-value-input"
              placeholder="Número de teléfono"
              v-model="item.telephoneNo"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="contactInfo-bottom">
      <van-button
        class="primary-btn"
        :disabled="isReview"
        round
        block
        @click="submit"
        >Próximo paso</van-button
      >
    </div> -->
  </div>
  <RetainPopup v-model:visible="visibleRetainPopup" :type="1"></RetainPopup>
</template>

<script>
import { Uploader } from "vant";
import { ref } from "vue";
import { useStore } from "vuex";

import RetainPopup from "./components/retainPopup.vue";
export default {
  name: "contactInfo",
  components: {
    [Uploader.name]: Uploader,
    RetainPopup,
  },
  setup() {
    const store = useStore();

    const submit = function () {};
    const isReview = ref(true);
    const visibleRetainPopup = ref(false);
    const contactList = store.getters.contactList || [];
    return {
      submit,
      isReview,
      visibleRetainPopup,
      contactList,
    };
  },
};
</script>

<style lang="less" scoped>
.contactInfo {
  position: relative;
  padding: 0 0 100px 0;
  height: 100vh;
  overflow-y: scroll;
  background-color: #f2f4f7;
  &-card {
    margin: 14.5px 10px;
    padding: 20px 15px 6px;
    font-weight: bold;
    border-radius: 25px;
    background-color: #fff;
    &-empty {
      padding: 10px 0;
      color: #888f99;
      text-align: center;
    }
    &-row {
      margin: 0 0 20px;
      &-title {
        margin: 0 0 6px;
        font-size: 16px;
        color: #3f3d5a;
      }
      &-imgs {
        &-item {
          margin: 0 10px 10px 0;
          width: 100px;
          height: 100px;
          border-radius: 8px;
          background-color: #f5f7fa;
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
      &-box {
        margin: 0 0 10px 0;
        font-size: 16px;
        color: #888f99;
        border-radius: 8px;
        background-color: #f2f4f7;
      }
      &-value {
        padding: 10px 16px;
        height: 50px;
        display: flex;
        align-items: center;
        &-active {
          color: #130e3d;
        }
        &-input {
          background-color: transparent;
          border: none;
          color: #888F99;
        }
        &-arrow {
          margin: 0 0 0 auto;
          width: 11px;
          height: 11px;
        }
      }
    }
  }
  &-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 7.5px 15px;
    background-color: #fff;
  }
}
</style>