<template>
  <van-popup
    v-model:show="isShow"
    safe-area-inset-bottom
    position="center"
    @close="$emit('update:visible', false)"
  >
    <div class="logout">
      <div class="logout-title">Consejos amables</div>
      <div class="logout-desc">Su cuenta ha sido cancelada con éxito</div>
      <div class="logout-option">
        <div class="logout-option-btn" @click="toHandle">confirmar</div>
      </div>
    </div>
  </van-popup>
</template>
<script>
import { ref, watch } from "vue";
import { Popup } from "vant";
import { useRouter } from "vue-router";

export default {
  props: {
    visible: { type: Boolean, default: false },
    type: { type: Number, default: 0 },
  },
  emits: ["update:visible"],
  components: { [Popup.name]: Popup },
  setup(props) {
    const isShow = ref(false);
    const router = useRouter();
    let userAgent = navigator.userAgent.toUpperCase(); //获取UA信息
    watch(
      () => props.visible,
      (val) => {
        isShow.value = val;
      }
    );
    const toHandle = () => {
      if (userAgent.indexOf("APP_INNER") != -1) {
        /* eslint-disable */
        openPage.postMessage("home");
        /* eslint-enable */
      } else {
        sessionStorage.clear();
        router.push("login");
      }
    };

    return {
      isShow,
      toHandle,
    };
  },
};
</script>
<style lang="less" scoped>
.primary-btn,
.default-btn {
  height: 44px;
  font-size: 16px;
}
.logout {
  width: 300px;
  padding: 33px 20px 25px;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  background-color: #fff;
  &-title {
    margin: 0 auto;
    line-height: 18px;
    font-size: 21px;
    background-size: 100% 100%;
  }
  &-desc {
    margin: 20px 0 0;
    color: #5e5d6e;
    font-size: 15px;
    text-align: left;
  }
  &-option {
    margin: 30px 0 0;
    &-btn {
      margin: 0 10px 0 0;
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
      text-align: center;
      background: linear-gradient(270deg, #33a68c 0%, #0e8e6b 100%);
      border-radius: 27.5px;
    }
  }
}
</style>
