import { needUpdate } from '@/api/app.js'
import { landPageClick, landPageDetail } from '@/api/common'

import { Toast } from 'vant'

export function openApp({ url_android_download, url_ios_download }) {
  // 说明: 当点击打开某一app按钮时, 判断手机是否安装此款app, 有的话就直接打开app首页, 没安装app就直接跳转对应的app store.

  if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
    console.log(12)
    const loadTime = new Date().getTime()
    // location.href = url_ios;
    setTimeout(function () {
      const outTime = new Date().getTime()
      if (outTime - loadTime > 800) {
        location.href = url_ios_download
      }
    }, 1000)
  } else if (navigator.userAgent.match(/android/i)) {
    console.log(34)
    setTimeout(function () {
      location.href = url_android_download
    }, 200)
  }
}

export async function downApp(phone) {
  console.log('downAppPhone', phone)
  const landPageId = sessionStorage.getItem('landPageId')
  if (landPageId) {
    await landPageClick(landPageId)
    const res = await landPageDetail(landPageId)
    if (!res.androidDownloadUrl) {
      res.androidDownloadUrl = ''
    }
    if (!res.iosDownloadUrl) {
      res.iosDownloadUrl = ''
    }
    if (res.androidDownloadUrl.indexOf('h5') != -1 || res.androidDownloadUrl.indexOf('repayment') != -1) {
      if (res.iosDownloadUrl.indexOf('h5') != -1 || res.iosDownloadUrl.indexOf('repayment') != -1) {
        openApp({ url_android_download: res.androidDownloadUrl + '?oldphone=' + phone, url_ios_download: res.iosDownloadUrl + '?oldphone=' + phone })
        return
      } else {
        openApp({ url_android_download: res.androidDownloadUrl + '?oldphone=' + phone, url_ios_download: res.iosDownloadUrl })
        return
      }
    } else {
      if (res.iosDownloadUrl.indexOf('h5') != -1 || res.iosDownloadUrl.indexOf('repayment') != -1) {
        openApp({ url_android_download: res.androidDownloadUrl, url_ios_download: res.iosDownloadUrl + '?oldphone=' + phone })
        return
      } else {
        openApp({ url_android_download: res.androidDownloadUrl, url_ios_download: res.iosDownloadUrl })
        return
      }
    }
  }
  needUpdate().then((res) => {
    if (!res.downloadUrl) {
      Toast('Operación exitosa,Descarga la aplicación del mercado!')
      return
    }
    if (res.downloadUrl.indexOf('h5') != -1 || res.downloadUrl.indexOf('repayment') != -1) {
      if (res.platform == 1) {
        openApp({ url_android_download: res.downloadUrl + '?oldphone=' + phone, url_ios_download: '' })
      } else {
        openApp({ url_android_download: '', url_ios_download: res.downloadUrl + '?oldphone=' + phone })
      }
    } else {
      if (res.platform == 1) {
        openApp({ url_android_download: res.downloadUrl, url_ios_download: '' })
      } else {
        openApp({ url_android_download: '', url_ios_download: res.downloadUrl })
      }
    }
  })
}
