import request from '@/utils/request'

//app版本
export async function needUpdate() {
  return request({
    url: '/appVersion/needUpdate',
    method: 'get'
  })
}
// 数据字典
export async function dictInfo() {
  return request({
    url: '/dict/findDictInfo',
    method: 'get'
  })
}
// 行业
export async function findListByTypeCode(data) {
  return request({
    url: '/optionInfo/findListByTypeCode',
    method: 'post',
    data
  })
}
// 下载页点击
export async function landPageClick(landPageId) {
  return request({
    url: `/landPage/click/${landPageId}`,
    method: 'get'
  })
}
// 下载页信息
export async function landPageDetail(landPageId) {
  return request({
    url: `/landPage/detail/${landPageId}`,
    method: 'get'
  })
}
