<template>
  <van-popup
    v-model:show="isShow"
    safe-area-inset-bottom
    position="center"
    @close="$emit('update:visible', false)"
  >
    <div class="logout">
      <div class="logout-title">Consejos</div>
      <div class="logout-desc">
        Tenga en cuenta que después de cancelar la cuenta, no se puede
        recuperar, opere con precaución.
      </div>
      <div class="logout-option">
        <div class="logout-option-btn" @click="$emit('update:visible', false)">
          Cancelar
        </div>
        <div class="logout-option-btn" @click="toLogout">Continuar</div>
      </div>
    </div>
  </van-popup>
</template>
<script>
import { ref, watch } from "vue";
import { Popup } from "vant";
import { logOut } from "../../../api/user";

export default {
  props: {
    visible: { type: Boolean, default: false },
  },
  emits: ["update:visible"],
  components: { [Popup.name]: Popup },
  setup(props, { emit }) {
    const isShow = ref(false);
    watch(
      () => props.visible,
      (val) => {
        isShow.value = val;
      }
    );
    const toLogout = () => {
      logOut().then((res) => {
        if (res?.code == 408) {
          // router.push('logoutErr')
          emit("change", "logoutErr");
        } else {
          emit("change");
        }
      });
    };

    return {
      isShow,
      toLogout,
    };
  },
};
</script>
<style lang="less" scoped>
.primary-btn,
.default-btn {
  height: 44px;
  font-size: 16px;
}
.logout {
  width: 300px;
  padding: 24px 20px;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  background-color: #fff;
  &-title {
    margin: 0 auto;
    width: 135px;
    line-height: 22px;
    font-size: 21px;
    color: #e34443;
    background-size: 100% 100%;
  }
  &-desc {
    margin: 20px 0 0;
    color: #5e5d6e;
    font-size: 15px;
    text-align: center;
  }
  &-option {
    margin: 30px 0 0;
    display: flex;
    &-btn {
      margin: 0 10px 0 0;
      width: 125px;
      height: 44px;
      line-height: 44px;
      color: #5e5d6e;
      text-align: center;
      font-size: 16px;
      background-color: #f2f4f7;
      border-radius: 22px;
      &:last-child {
        color: #fff;
        background: linear-gradient(270deg, #f78b8b 0%, #ee4747 100%);
      }
    }
  }
}
</style>
