import request from '@/utils/request'

//是否可申贷
export async function canApply(params) {
    return request({
        url: '/application/canApply',
        method: 'get',
        params,
    })
}
// 产品列表
export async function analyzeLoan(params) {
    return request({
        url: '/loanRate/analyzeLoan',
        method: 'get',
        params,
    })
}
// 计算产品明细
export async function calculateFees(params) {
    return request({
        url: '/loanRate/calculateFees',
        method: 'get',
        params,
    })
}
// 申请贷款
export async function apply(data) {
    return request({
        url: '/application/apply',
        method: 'post',
        data,
        isEncryption:true
    })
}

