<template>
    <NavBack title="Confirmar Préstamo"></NavBack>
    <div class="product">
        <div class="product-card">
            <div class="product-card-title">Monto del préstamo</div>
            <div class="product-card-list">
                <div class="product-card-list-item" :class="{ 'product-card-list-item-active': chooseMoney == i }"
                    @click="changeMoney(i)" v-for="(i, index) of money" :key="index">
                    <span class="money">$ {{ $thousands(i) }}</span>
                </div>
            </div>
            <div v-if="chooseMoney" class="product-card-title">Plazo del préstamo</div>
            <div v-if="chooseMoney" class="product-card-list">
                <div class="product-card-list-item" :class="{ 'product-card-list-item-active': chooseDay == i }"
                    @click="changeDay(i)" v-for="(i, index) of day" :key="index">
                    <div class="day">{{ i }}</div>
                    <div class="unit">días</div>
                </div>
            </div>
        </div>
        <div v-if="productDetail">
            <div class="product-detail">
                <div class="product-detail-title">Detalles del préstamo</div>
                <div class="product-detail-row">
                    <div class="product-detail-row-label"> <span>Monto del préstamo</span> </div>
                    <div>
                        <!-- 贷款额度 -->
                        ${{ $thousands(productDetail.amount) }}
                    </div>
                </div>
                <div class="product-detail-row">
                    <div class="product-detail-row-label">
                        <span>Intereses - </span>
                        <span class="product-detail-row-label-sub">(prepago)</span>
                    </div>
                    <div>
                        <!-- 利息 --> ${{ $thousands(productDetail.interestAmount) }}
                    </div>
                </div>
                <div class="product-detail-row">
                    <div class="product-detail-row-label">
                        <span>Tarifa de auditoría - </span>
                        <span class="product-detail-row-label-sub">(prepago)</span>
                    </div>
                    <div>
                        <!-- 审计费 -->
                        ${{ $thousands(productDetail.auditServiceFee) }}
                    </div>
                </div>
                <!-- <div class="product-detail-row">
                    <div class="product-detail-row-label"> <span>VAT</span> </div>
                    <div>$ 6500</div>
                </div> -->
                <div class="product-detail-row">
                    <div class="product-detail-row-label"> <span>Fecha de caducidad</span> </div>
                    <div>
                        <!-- 应还款时间 -->
                        {{ $dayjs(productDetail.estimateDueDate).format("DD-MM-YYYY") }}
                    </div>
                </div>
                <div class="product-detail-row">
                    <div class="product-detail-row-label"> <span>Cantidad recibida</span> </div>
                    <div class="green">
                        <!-- 到手金额 -->${{ $thousands(productDetail.receivedAmount) }}
                    </div>
                </div>
            </div>
            <div class="product-detail">
                <div class="product-detail-title">Vencido y pagadero</div>
                <div class="product-detail-row">
                    <div class="product-detail-row-label"> <span>Tarifa de servicio </span> </div>
                    <!-- 后置服务费 -->
                    <div>${{ $thousands(productDetail.afterAuditServiceFee) }}</div>
                </div>
                <div class="product-detail-row">
                    <div class="product-detail-row-label"> <span>Vencido y pagadero</span> </div>
                    <div class="green">${{ $thousands(productDetail.repaymentAmount) }}</div>
                </div>
            </div>
            <div v-if="userInfo" class="product-detail">
                <div class="product-detail-title">Información de retiro</div>
                <div class="product-detail-row">
                    <div class="product-detail-row-label"> <span>Nombre del banco</span> </div>
                    <div>{{ userInfo.bank }}</div>
                </div>
                <div class="product-detail-row">
                    <div class="product-detail-row-label"> <span>Cuenta receptora</span> </div>
                    <div class="green">**** {{ userInfo.accountNo ? userInfo.accountNo.slice(-4) : '' }}</div>
                </div>
            </div>
        </div>

        <div class="product-footer">
            <van-button class="primary-btn" :disabled="!productDetail" block round @click="toLoan">Confirmar la aplicación</van-button>
        </div>
    </div>
</template>

<script>
import { analyzeLoan, calculateFees, apply } from '@/api/loan';
import { load } from '@/api/user'
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
export default {
    setup() {
        const router = useRouter()
        onMounted(() => {
            getProduct()
            getBank()
        })
        let productDetail = ref()
        const userInfo = ref()
        const getBank = async () => {
            userInfo.value = await load()
        }

        const product = ref([]);
        const getProduct = async () => {
            const res = await analyzeLoan()
            product.value = res
            handleProduct()
        }

        let money = ref([])
        let chooseMoney = ref()
        let day = ref([])
        let chooseDay = ref()
        const handleProduct = () => {
            product.value.forEach(i => {
                if (!money.value.includes(i.amount)) {
                    money.value.push(i.amount)
                }
                if (!day.value.includes(i.period)) {
                    day.value.push(i.period)
                }
            });
        }
        const changeMoney = (amount) => {
            chooseMoney.value = amount
            chooseDay.value = ''
            day.value = []
            productDetail.value = null
            product.value.forEach(i => {
                if (i.amount == amount) {
                    day.value.push(i.period)
                }
            });
        }
        const changeDay = (period) => {
            chooseDay.value = period
        }
        const chooseProduct = ref()

        watch(() => chooseDay.value, () => {
            const tem = product.value.find(i => i.period == chooseDay.value && i.amount == chooseMoney.value)
            if (tem) { getProductDetail(tem) }
            chooseProduct.value = tem || ''
        })

        const getProductDetail = async (tem) => {
            productDetail.value = await calculateFees({ rateId: tem.id })
        }

        const toLoan = async () => {
            const res = await apply({ loanRateId: chooseProduct.value.id })
            console.log(res)
            if(res){
                router.push('/home')
            }
        }

        return {
            money, day, chooseMoney, chooseDay, chooseProduct, productDetail, userInfo,
            changeMoney, changeDay, toLoan
        }
    }
}
</script>

<style lang="less" scoped>
.product {
    padding: 15px 10px 90px;
    height: calc(100vh - 70px);
    box-sizing: border-box;
    overflow-y: scroll;
    background-color: @Main-bg;

    &-card {
        padding: 24px 15px;
        font-weight: bold;
        border-radius: 15px;
        background-color: #fff;

        &-title {
            font-size: 16px;

        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 30px;

            &:last-child {
                margin: 0;
            }

            &-item {
                margin: 10px 10px 0 0;
                padding: 15px;
                color: #212750;
                text-align: center;
                background-color: @Main-bg;
                border-radius: 12px;

                &:last-child {
                    margin: 10px 0 0;
                }

                .money {
                    font-size: 27px;
                }

                .day {
                    font-size: 25px;
                }

                .unit {
                    margin: 8px 0 0;
                    font-size: 15px;
                }
            }

            &-item-active {
                color: #fff;
                background: linear-gradient(to right, @MainColor, @SecondaryColor);

                .money {
                    font-size: 30px;
                }

                .day {
                    font-size: 30px;
                }
            }
        }
    }

    &-detail {
        margin: 15px 0;
        padding: 27px 15px;
        font-weight: bold;
        border-radius: 15px;
        background-color: #fff;

        &-title {
            margin: 0 0 20px;
            color: #212750;
            font-size: 19px;
        }

        &-row {
            margin: 0 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;

            &-label {
                font-size: 16px;
                color: rgba(33, 39, 80, .7);

                &-sub {
                    color: #81939B;
                }
            }

            .green {
                color: @MainColor;
                font-size: 24px;
            }

            &:last-child {
                margin: 0;
            }
        }
    }

    &-footer {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 7.5px 9px;
        background-color: #fff;
    }
}
</style>