import request from '@/utils/request'

//
export async function getApplicationInfo(params) {
    return request({
        url: `/application/${params.applicationId}/applicationInfo`,
        method: 'get',
    })
}
//最近订单
export async function latest(params) {
    return request({
        url: '/application/latest',
        method: 'get',
        params,
    })
}
// 计算展期金额
export async function queryDelayAmount(params) {
    return request({
        url: '/mexicoRepayment/queryDelayAmount',
        method: 'get',
        params,
    })
}