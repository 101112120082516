<template>
  <div class="contact">
    <NavBack title="Informacion personal"></NavBack>
    <div class="contact-center">
      <div class="contact-center-desc">
        Si tiene alguna pregunta, comuníquese con no-sotros a través de los
        siguientes métodos.
      </div>
      <div class="contact-center-card">
        <div class="contact-center-card-title">
          Línea de atención al cliente
        </div>
        <div class="contact-center-card-value">
          {{ contactMethod.phone || "" }}
        </div>
      </div>
      <div class="contact-center-card">
        <div class="contact-center-card-title">
          Correo electrónico de soporte
        </div>
        <div
          class="contact-center-card-value"
          @click="copyEmail(contactMethod.email) || ''"
        >
          {{ contactMethod.email || "" }}
        </div>
      </div>
      <div class="contact-center-card" v-if="contactMethod.whatsapp">
        <div class="contact-center-card-title">WhatsApp</div>
        <a :href="'tel:' + contactMethod.whatsapp">
          <div class="contact-center-card-value">
            {{ contactMethod.whatsapp ||"" }}
          </div>
        </a>
      </div>
      <div class="contact-center-card">
        <div class="contact-center-card-title">Número de versión actual</div>
        <div class="contact-center-card-value">V{{ appVersion || appConfig.appVersion }}</div>
      </div>
    </div>
      <div class="contact-btn" @click="$router.push('logout')">Cancelar la cuenta</div>

  </div>
</template>

<script>
import { useStore } from "vuex";
import appConfig from "@/config/app_config.js";
import { copyText } from "@/utils/tools.js";
import {  getCurrentInstance, onMounted, ref } from "vue";
export default {
  name: "Contact",
  setup() {
    const store = useStore();
    const { appContext } = getCurrentInstance();
    const $toast = appContext.config.globalProperties.$toast;
    const contactMethod = ref({});
    const appVersion = sessionStorage.getItem('appVersion')
    onMounted(async () => {
      const res = await store.dispatch("getDict");
      contactMethod.value = {
        email: res.feedbackEmail,
        phone: res.customerServicePhone,
        whatsapp: res.whatsapp,
      };
    });
    function copyEmail(email) {
      copyText(email);
      $toast("Copy Successfully");
    }

    return {
      contactMethod,
      appConfig,
      copyEmail,
      appVersion
    };
  },
};
</script>

<style lang="less" scoped>
.contact {
  height: 100vh;
  background-color: #f2f4f7;
  &-center {
    padding: 0 15px;
    font-weight: bold;
    &-desc {
      padding: 12px 0;
      font-size: 15px;
      line-height: 1.5;
      color: #5e5d6e;
    }
    &-card {
      margin: 0 0 15px 0;
      padding: 20px;
      border-radius: 25px;
      background: #fff;
      &-title {
        font-size: 15px;
        color: #5e5d6e;
      }
      &-value {
        margin: 19px 0 0;
        font-size: 21px;
        color: #130e3d;
      }
    }
  }
  &-btn{
    position: fixed;
    left: 15px;
    right: 15px;
    bottom:20px;
    height: 55px;
    line-height: 55px;
    font-size: 19px;
    font-weight: bold;
    color: @MainColor;
    text-align: center;
    border-radius: 27.5px;
    background:#fff;
  }
}
</style>