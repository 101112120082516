<template>
  <div class="workInfo">
    <NavBack title="Informacion personal"></NavBack>
    <div class="workInfo-card">
      <div class="workInfo-card-row">
        <div class="workInfo-card-row-title">Puesto de trabajo</div>
        <div
          class="workInfo-card-row-value"
          :class="{ 'workInfo-card-row-value-active': !isReview }"
        >
          <!-- 工作/行业类型 -->
          <!-- <input
            type="text"
            class="workInfo-card-row-value-input"
            placeholder="Por favor elige"
            disabled
            v-model="industry.name"
          /> -->
          <div class="workInfo-card-row-value-input">
            {{ industry?.name || "--" }}
          </div>
          <img
            class="workInfo-card-row-value-arrow"
            src="../../assets/icons/arrow_step1@3x.png"
          />
        </div>
      </div>
      <div class="workInfo-card-row">
        <div class="workInfo-card-row-title">Nombre de empresa</div>
        <div
          class="workInfo-card-row-value"
          :class="{ 'workInfo-card-row-value-active': !isReview }"
        >
          <!-- 公司名字 -->

          <!-- <input
            type="text"
            class="workInfo-card-row-value-input"
            placeholder="Por favor elige"
            disabled
            v-model="userInfo.companyName"
          /> -->
          <div class="workInfo-card-row-value-input">{{userInfo?.companyName||'--'}}</div>
          <img
            class="workInfo-card-row-value-arrow"
            src="../../assets/icons/arrow_step1@3x.png"
          />
        </div>
      </div>
      <div class="workInfo-card-row">
        <div class="workInfo-card-row-title">Teléfono de la empresa</div>
        <div
          class="workInfo-card-row-value"
          :class="{ 'workInfo-card-row-value-active': !isReview }"
        >
          <!-- 公司电话 -->
          <!-- <input
            class="workInfo-card-row-value-input"
            disabled
            type="text"
            placeholder="Por favor escribe"
            v-model="userInfo.companyTelephone"
          /> -->
          <div class="workInfo-card-row-value-input">{{userInfo?.companyTelephone || '--'}}</div>
          <img
            class="workInfo-card-row-value-arrow"
            src="../../assets/icons/arrow_step1@3x.png"
          />
        </div>
      </div>
      <div class="workInfo-card-row">
        <div class="workInfo-card-row-title">Ciclo de pago</div>
        <div
          class="workInfo-card-row-value"
          :class="{ 'workInfo-card-row-value-active': !isReview }"
        >
          <!-- 发薪周期 -->
          <!-- <input
            class="workInfo-card-row-value-input"
            disabled
            placeholder="Por favor elige"
            type="text"
            v-model="userInfo.paydayType"
          /> -->
          <div>{{payDate?.name||'--'}}</div>
          <img
            class="workInfo-card-row-value-arrow"
            src="../../assets/icons/arrow_step1@3x.png"
          />
        </div>
      </div>
      <div class="workInfo-card-row">
        <div class="workInfo-card-row-title">
          Permiso de trabajo / certificado de salario
        </div>
        <div class="workInfo-card-row-imgs">
          <!-- 收入证明 -->
          <van-image
            v-if="userInfo?.salaryRecordPhotoUrl"
            class="workInfo-card-row-imgs-item"
            fit="contain"
            :src="userInfo?.salaryRecordPhotoUrl"
          />
          <!-- 工作证 -->
          <van-image
            v-if="userInfo?.workPermitPhotoUrl"
            class="workInfo-card-row-imgs-item"
            fit="contain"
            :src="userInfo?.workPermitPhotoUrl"
          />
        </div>
      </div>
      <div class="workInfo-card-row">
        <div class="workInfo-card-row-title">Pagar tiempo</div>
        <div
          class="workInfo-card-row-value"
          :class="{ 'workInfo-card-row-value-active': !isReview }"
        >
          <!-- 支付时间 -->
          <div class="workInfo-card-row-value-input">
            {{userInfo?.paydayDate  || '--'}}
          </div>
          <img
            class="workInfo-card-row-value-arrow"
            src="../../assets/icons/arrow_step1@3x.png"
          />
        </div>
      </div>
      <div class="workInfo-card-row">
        <div class="workInfo-card-row-title">Duración del trabajo</div>
        <div
          class="workInfo-card-row-value"
          :class="{ 'workInfo-card-row-value-active': !isReview }"
        >
          <!-- 工作时间 -->
          <!-- <input
            class="workInfo-card-row-value-input"
            disabled
            type="text"
            placeholder="Por favor elige"
            v-model="workingTime.name"
          /> -->
          <div class="workInfo-card-row-value-input">{{workingTime?.name || '--'}}</div>
          <img
            class="workInfo-card-row-value-arrow"
            src="../../assets/icons/arrow_step1@3x.png"
          />
        </div>
      </div>
    </div>
    <!-- <div class="workInfo-bottom">
      <van-button
        class="primary-btn"
        :disabled="isReview"
        round
        block
        @click="submit"
        >Próximo paso</van-button
      >
    </div> -->
  </div>
  <RetainPopup v-model:visible="visibleRetainPopup" :type="0"></RetainPopup>
</template>

<script>
import { Uploader } from "vant";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import RetainPopup from "./components/retainPopup.vue";
export default {
  name: "WorkInfo",
  components: {
    [Uploader.name]: Uploader,
    RetainPopup,
  },
  setup() {
    const store = useStore();
    const submit = function () {};
    const isReview = ref(false);
    const visibleRetainPopup = ref(false);
    const userInfo = computed(() => store.state.userInfo);
    const industry = userInfo.value?.industry? store.getters.getIndustryById(userInfo.value.industry):'--';
    const payDate = userInfo.value?.paydayType ?store.getters.getPaydayTypeById(userInfo.value.paydayType):'--';
    const workingTime = userInfo.value?.workingTime ?store.getters.getWorkingtimeById(
      userInfo.value.workingTime
    ):'--';

    return {
      submit,
      isReview,
      visibleRetainPopup,
      userInfo,
      industry,
      payDate,
      workingTime,
    };
  },
};
</script>

<style lang="less" scoped>
.workInfo {
  position: relative;
  padding: 0 0 100px 0;
  height: 100vh;
  overflow-y: scroll;
  background-color: #f2f4f7;
  &-card {
    margin: 14.5px 10px;
    padding: 20px 15px 6px;
    font-weight: bold;
    border-radius: 25px;
    background-color: #fff;
    &-row {
      margin: 0 0 20px;
      &-title {
        margin: 0 0 6px;
        font-size: 16px;
        color: #3f3d5a;
      }
      &-imgs {
        &-item {
          margin: 0 10px 10px 0;
          display: inline-block;
          width: 100px;
          height: 100px;
          border-radius: 8px;
          background-color: #f5f7fa;
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
      &-value {
        padding: 10px 16px;
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #888f99;
        border-radius: 8px;
        background-color: #f2f4f7;
        &-active {
          color: #130e3d;
        }
        &-input {
          background-color: transparent;
          border: none;
        }
        &-arrow {
          margin: 0 0 0 auto;
          width: 11px;
          height: 11px;
        }
      }
    }
  }
  &-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 7.5px 15px;
    background-color: #fff;
  }
}
</style>