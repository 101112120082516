<template>
  <van-popup
    v-model:show="isShow"
    safe-area-inset-bottom
    position="center"
    @close="$emit('update:visible', false)"
  >
    <div class="retainPopup">
      <div class="retainPopup-title">
        <span>Consejos</span>
      </div>
      <div class="retainPopup-desc">
        {{ descList[type] }}
      </div>
      <div class="retainPopup-actions">
        <van-button class="default-btn" round @click="$router.go(-1)">Rendirse</van-button>
        <van-button
          class="primary-btn"
          round
          @click="$emit('update:visible', false)"
          >Seguir</van-button
        >
      </div>
    </div>
  </van-popup>
</template>
<script>
import { ref, watch } from "vue";
import { Popup } from "vant";

export default {
  props: {
    visible: { type: Boolean, default: false },
    type: { type: Number, default: 0 },
  },
  emits: ["update:visible"],
  components: { [Popup.name]: Popup },
  setup(props) {
    const isShow = ref(false);
    watch(
      () => props.visible,
      (val) => {
        isShow.value = val;
      }
    );
    const descList = [
      "Hay dos pasos más para completar la información para solicitar un préstamo. ¿Está seguro de que desea darse por vencido?",
      "Aún queda el último paso y la solicitud de préstamo está completa ¿Estás seguro de que quieres deshacerte de ella?",
      "Solo necesitas tres pasos para completar la información, puedes solicitar el préstamo, ¿estás seguro de que te rendirás?",
    ];
    return {
      isShow,
      descList,
    };
  },
};
</script>
<style lang="less" scoped>
.primary-btn,.default-btn{
    height: 44px;
    font-size: 16px;
}
.retainPopup {
  width: 300px;
  padding: 24px 20px;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  background-color: #fff;
  &-title {
    color: #100c33;
    font-size: 21px;
  }
  &-desc {
    margin: 20px 0 0;
    color: #888f99;
    font-size: 14px;
    line-height: 1.5;
  }
  &-actions {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>
