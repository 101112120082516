import request from '@/utils/request'

//保存放款银行账户
export async function checkBankAccount(data) {
    return request({
        url: '/mexicoRepayment/checkBankAccount',
        method: 'post',
        data,
    })
}
//还款方式
export async function repayList(data) {
    return request({
        url: '/mexicoRepayment/repayList',
        method: 'post',
        data,
    })
}
//还款信息
export async function repayVaNumber(data) {
    return request({
        url: '/mexicoRepayment/repayVaNumber',
        method: 'post',
        data,
    })
}
//新还款信息
export async function repayVaNumberNew(data) {
    return request({
        url: `/mexicoRepayment/repayVaNumberNew/${data.orderId}`,
        method: 'post',
        data,
    })
}