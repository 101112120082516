import {
    createApp
} from 'vue'
import 'lib-flexible/flexible'
import dayjs from 'dayjs'
import App from './App.vue'
import router from './router'
import store from './store'
import {
    NavBar,
    Button,
    Toast,
    Image as VanImage
} from 'vant';
import NavBack from '@/components/NavBack.vue'
import { thousands } from '@/utils/tools.js'
import { install as Icon } from "@/icon"


import "windi.css"

window.getToken = function (res) {
    // app端获取token
    sessionStorage.setItem("token", res);
    console.log('Token', res)
}
const app = createApp(App);

app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$thousands = thousands

Icon(app)

app.directive('preventReClick', (el, binding) => {
    function preventReClickFun(elValue, bindingValue) {

        if (!elValue.disabled) {
            elValue.disabled = true
            setTimeout(() => {
                elValue.disabled = false
            }, bindingValue.value || 3000)
        }
    }
    el.addEventListener('click', () => preventReClickFun(el, binding))
    binding.dir.unmounted = function () {
        el.removeEventListener('click', () => preventReClickFun(el, binding))
    }
});


app.use(Button);
app.use(NavBar);
app.use(Toast);
app.use(VanImage);


app.component('NavBack', NavBack)
app.use(store).use(router).mount('#app')