<template>
  <NavBack title="Detalles del cupón"></NavBack>
  <div class="couponDetail">
    <div class="couponDetail-box">
      <div class="couponDetail-top">
        <div class="couponDetail-top-title">{{ info.name }}</div>
        <div class="couponDetail-top-number">
          ${{ $thousands(info.discountAmount) }}
        </div>
      </div>
      <div class="couponDetail-list">
        <div class="couponDetail-list-title">Tiempo de uso</div>
        <div class="couponDetail-list-content">
          <span v-if="info.validType == 1">
            {{ info.startUsableDate }} - {{ info.endUsableDate }}</span>
          <span v-if="info.validType == 2">Caduca después de {{ info.receiveDay }} días a partir de la fecha
            de recolección</span>
        </div>
        <div class="couponDetail-list-title">Instrucciones de uso</div>
        <div class="couponDetail-list-content">
          {{ info.descExplain }}
        </div>
      </div>
    </div>
    <div v-if="!isReceived" class="couponDetail-btn" @click.stop="toReceive">
      Consíguelo ahora
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { Toast } from "vant";

import { getByIdCoupon, receiveCoupon } from "@/api/coupon.js";
export default {
  setup() {
    const couponId = sessionStorage.getItem("couponId");
    const isReceived = ref(false);
    const info = ref({
      discountAmount: 0,
      startUsableDate: null,
      endUsableDate: null,
      descExplain: "",
    });
    const toReceive = async () => {
      const result = await receiveCoupon({ couponId });
      if (result == true) {
        isReceived.value = true;
        Toast("¡Felicidades! Cupón recibido con éxito");
      }
    };
    const getInfo = async () => {
      const res = await getByIdCoupon(couponId);
      info.value = res;
    };
    onMounted(() => {
      getInfo();
    });
    return { isReceived, info, toReceive };
  },
};
</script>
<style lang="less" scoped>
.couponDetail {
  position: relative;
  padding: 15px 0 10px;
  min-height: calc(100vh - 70px);
  background-color: #F3F2F9;

  &-box {
    position: relative;
    margin: 15px;
    border-radius: 15px;
    background-color: #fff;
  }

  &-top {
    padding: 30px 0;
    color: #fff;
    text-align: center;
    background: url('../../assets/images/bg_detail1@3x.png');
    background-size: 100% 100%;
    font-size: 19px;
    font-weight: bold;

    &-number {
      margin: 10px 0 0;
      font-size: 55px;
    }
  }

  &-list {
    padding: 35px 27.5px;

    &-title {
      position: relative;
      text-indent: 16px;
      font-size: 18px;
      font-weight: bold;
      color: #212750;

      &::before {
        position: absolute;
        top: 40%;
        left: 0;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: @MainColor;
      }
    }

    &-content {
      margin: 8px 0 35px 0;
      font-weight: bold;
      color: #81939B;
      line-height: 2;
      word-break: break-all;
      font-size: 15px;

      &:last-child {
        margin: 10px 0 0;
        min-height: 144px;
      }
    }
  }

  &-btn {
    position: fixed;
    left: 15px;
    right: 15px;
    bottom: 15px;
    height: 55px;
    line-height: 55px;
    border-radius: 8px;
    text-align: center;
    color: #fff;
    font-size: 19px;
    font-weight: bold;
    background: linear-gradient(to bottom, @SecondaryColor, @MainColor)
  }
}
</style>