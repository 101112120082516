<template>
    <svg :class="svgClass" v-bind="$attr">
        <use :xlink:href="iconName"></use>
    </svg>
</template>
  
  <script>
import { computed } from 'vue'
export default {
    name: 'svg-component',

    props: {
        iconClass: {
            type: String,
            required: true
        },
        className: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const iconName = computed(() => `#icon-${props.iconClass}`)
        const svgClass = computed(() => {
            if (props.className) {
                return 'svg-icon' + props.className
            } else {
                return 'svg-icon'
            }
        })

        return {
            iconName, svgClass
        }
    }
}
</script>
  
  <style scoped>

  .svg-icon {
      width: 1em;
      height: 1em;
      display: inline-block;
      vertical-align: -0.15em;
      overflow: hidden;
  }
  
</style>