import request from '@/utils/request'

// app 下载链接
export async function needUpdate(params) {
    return request({
        url: '/appVersion/needUpdate',
        method: 'get',
        params
    })
}

// 验证用户是否是老用户
export async function verifyPhoneRegister(params) {
    return request({
        url: '/appVersion/verifyPhoneRegister',
        method: 'get',
        params
    })
}
// 关联电销员
export async function associateCallSale(params) {
    return request({
        url: '/appVersion/associateCallSale',
        method: 'post',
        // data,
        params,
    })
}
export async function appConfigType(params) {
    return request({
        url: '/systemConfig/appConfigType',
        method: 'get',
        params,
    })
}

// 下拉列表 行业
export async function findListByTypeCode(data){
    return request({
        url:'/optionInfo/findListByTypeCode',
        method:'post',
        data
    })
}
// app配置
export async function appParams(){
    return request({
        url:'/appVersion/getAppParams',
        method:'get',
    })
}